<template>
  <v-app v-scroll="onScroll">
    <router-view></router-view>
    <v-fab-transition>
      <v-btn
        color="blue darken-3"
        dark
        fab
        fixed
        bottom
        :right="showFabRight"
        :left="!showFabRight"
        v-show="showFab"
        @click="$vuetify.goTo('#app', { duration: 500, offset: 0 })"
        ><v-icon>keyboard_arrow_up </v-icon>
      </v-btn>
    </v-fab-transition>
  </v-app>
</template>
<script>
import { INVALID_REFRESH_TOKEN } from "@/appConstants";
import auth from "@/core/auth";
import companyconfig from "@/core/companyconfig";
import { loadedLanguages } from "@/plugins/i18n";
import api from "@/services/loginapi";
import { loadLanguageAsync } from "@/plugins/i18n";
import Vue from "vue";
const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
Vue.prototype.$token = urlParams.get('token');
Vue.prototype.$cn = urlParams.get('company_name');
Vue.prototype.$d = urlParams.get('d');
Vue.prototype.$r = urlParams.get('r');
if(Vue.prototype.$r != null) {
    Vue.localStorage.set('ref' + "_" + urlParams.get('company_name'), urlParams.get('r'));
}
    
export default {
  name: "App",
  data() {
    return {
      timer: "",
      offsetTop: 0,
      showFab: false,
      config: {},
      showFabRight: false
    };
  },
  created() {
    companyconfig.configVuetify(this.$vuetify);
    this.setupLayout();
    this.settimer(5000);
    this.loadMessagesOnScreenRefresh();

    this.saveGuestHash();
  },
  methods: {
    /**
     * Do the scroll and show the fab icon, which scroll to top
     */
    onScroll(e) {
      this.offsetTop =
        e.target.documentElement.scrollTop || e.currentTarget.scrollY;
        if (this.offsetTop > 100) {
          //Krystian asked to disable FAB icon... so I just commenting out it now, I know that in some time he will request it back again.... do not thank:)
          // this.showFab = true;
        } else {
          this.showFab = false;
        }
    },
    /**
     * Fetch the refresh token when token expires, support for interceptor
     */
    fetchRefreshToken() {
      if (auth.isLoggedIn() && auth.isTokenExpired()) {
        let rToken = auth.getIdToken();
        let self = this;
        return api
          .doRefreshToken(rToken)
          .then(function(success) {
            let data = success.data;
            if (success.message === INVALID_REFRESH_TOKEN) {
              let passedCompanyId = companyconfig.getCompanyIdfromUrl();
              let passedDeviceId = companyconfig.getDeviceIdfromUrl();
              this.$router.push({
                name: "welcome",
                query: {
                  company_name: passedCompanyId,
                  device_id: passedDeviceId
                }
              });
              auth.clearTokens();
              self.clearTimeout();
            } else if (data) {
              auth.setTokens(
                data.token,
                data.refresh_token,
                data.expiry_second
              );
              self.settimer(5000);
            }
          })
          .catch(function(error) {
            console.log("Refresh login error: ", error);
          });
      }
      this.settimer(5000);
    },
    /** On logout cancel the timer */
    cancelAutoUpdate() {
      clearTimeout(this.timer);
    },
    /** Intialize the timer */
    settimer(mlsec) {
      this.timer = setTimeout(() => {
        this.fetchRefreshToken();
      }, mlsec);
    },
    /**
     * Fetch the language when the user presee f5 on the browser
     */
    loadMessagesOnScreenRefresh() {
      if (loadedLanguages.length === 0) {
        let lang = auth.getAppLanguage();
        let data = companyconfig.getCompanyScheme(); // TODO: should be already loaded here, but check for a case when maybe it is not (on some bad network)

        let url_name = data.url_name;
        if (!url_name) {
          let url_string = window.location.href;
          let url = new URL(url_string);
          url_name = url.searchParams.get("company_name");
        }

        loadLanguageAsync(lang, url_name).then(() => {});
      }
    },
    /*
     * Set the Intial color configuration for page
     */
    setupLayout() {
      companyconfig.waitForCompanyScheme ((data) => { // TODO: refactorize to use async+await
        if (data.taskfabposition == "left") {
          this.showFabRight = true;
        } else {
          this.showFabRight = false;
        }

        // Function to parse SCRIPTs out of HTML string and append them to the target
        let appendScripts = function (htmlString, target) {
          var tempDiv = document.createElement('div');
          tempDiv.innerHTML = htmlString;
          var scripts = tempDiv.getElementsByTagName('script');
          var scriptsArray = Array.prototype.slice.call(scripts);
          scriptsArray.forEach(function(script) {
            var newScript = document.createElement('script');
            Array.prototype.slice.call(script.attributes).forEach(function(attr) {
              newScript.setAttribute(attr.name, attr.value);
            });
            newScript.textContent = script.textContent;
            document[target].appendChild(newScript);
          });
        };

        if(typeof data.layout_custom_js != 'undefined' && data.layout_custom_js != '') {
          appendScripts(data.layout_custom_js, 'body');
        }

        if(typeof data.header_custom_js != 'undefined' && data.header_custom_js != '') {
          appendScripts(data.header_custom_js, 'head');
        }

        if(typeof data.login_css != 'undefined' && data.login_css != '') {
          var styleNode = document.createElement('style');
          styleNode.type = 'text/css';
          styleNode.textContent = data.login_css;
          document.documentElement.appendChild(styleNode);
          window.document.body.appendChild(styleNode);
        }
      });
    },
    sendGtagBLMNotification() {
      var guest_hash = localStorage.getItem('guest_hash'),
          ga_notification_key = 'GUEST_HASH_GA_NOTIFIED',
          ga_notification_guest_hash = localStorage.getItem(ga_notification_key);

      if (guest_hash && typeof window.gtag !== 'undefined' && guest_hash !== ga_notification_guest_hash) {
        console.log('gtag BLM notification sent')
        window.gtag('event', 'BLM_LOGIN', {
          'event_category': 'company_name',
        });

        localStorage.setItem(ga_notification_key, guest_hash);
      }
    },
    saveGuestHash(){
      var url = window.location.href;
      var captured = /guest_hash=([^&]+)/.exec(url);
      var result = captured ? captured[1] : false;
      if(result) {
        localStorage.setItem('guest_hash', result);
      }
    }
  },
  mounted() {
    this.sendGtagBLMNotification();
  },
  beforeDestroy() {
    this.cancelAutoUpdate();
  }
};
</script>
