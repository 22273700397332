<template>
    <v-card flat tile>
        <v-toolbar
            dense
            :color="config.mcolor"
            :style="`color:${config.mfontcolor} !important`"
            flat>

            <v-btn
                icon
                class="border0px"
                @click="navigate"
                :style="`color:${config.mfontcolor} !important`">

                <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
                icon
                class="border0px"
                @click="navigatehome"
                :style="`color:${config.mfontcolor} !important`">

                <v-icon>mdi-home-circle-outline</v-icon>
            </v-btn>
        </v-toolbar>
<!--        <alert-->
<!--            :errorMessage="scanErrorMessage"-->
<!--            :alert="scanErrorExists"-->
<!--            :errorType="scanErrorType"-->
<!--        ></alert>-->
        <v-card-title id="scan-card-title">
            <v-row>
                <v-col cols="8" md="10">
                    <div :style="`color:${config.mfontcolor} !important`" v-text="$t('TaskScreen.SCAN_RECEIPT')"></div>
                </v-col>
                <v-col cols="4" md="2">
                    <v-btn
                        block
                        rounded
                        id="receipt-action-btn"
                        :style="`color:${config.mfontcolor}`"
                        :color="config.mcolor"
                        @click="choose"
                        v-text="$t('TaskScreen.SCAN')"></v-btn>
                </v-col>
            </v-row>
        </v-card-title>
        <div>
            <div v-if="task" id="task-description-inside">
                <span v-html="task.description" id="gamifiction-description"></span>
            </div>
        </div>
        <form enctype="multipart/form-data" novalidate>
            <input type="file" id="file" multiple name="files[]" accept="image/*" class="input-file" style="display: none" @change="onFileChange" >
        </form>

        <progressModal @progress-modal-hide="progressModalHide" v-if="status < 0 && !scanErrorExists"></progressModal>
        <successModal @success-modal-hide="successModalHide" v-if="status == 1" :receiptProcessingResultStatus="receiptProcessingResultStatus"></successModal>
        <errorModal @error-modal-hide="errorModalHide" v-if="scanErrorExists && scanErrorType == 'error'" :errorMessage="scanErrorMessage"></errorModal>
        <infoModal @error-modal-hide="errorModalHide" v-if="scanErrorExists && scanErrorType == 'info'" :errorMessage="scanErrorMessage"></infoModal>
        <rejectedModal @rejected-modal-hide="rejectedModalHide" v-if="status >= 3 && status != 6 && !scanErrorExists" :receiptProcessingResultStatus="receiptProcessingResultStatus"></rejectedModal>
        <correctionModal @correction-modal-hide="correctionModalHide" @correction-modal-next="correctionModalNext" @correction-modal-mark="correctionModalMark" v-if="status == 6 && !scanErrorExists"></correctionModal>
        <awaitingModal @awaiting-modal-hide="awaitingModalHide" v-if="status == 11 && !scanErrorExists"></awaitingModal>



    </v-card>
</template>
<script>
    const MAX_IMAGE_SIZE = 5242880

    import $ from 'jquery'
    import correctionModal from "../../../components/tasks/scan_modals/correctionModal";
    import errorModal from "../../../components/tasks/scan_modals/errorModal";
    import progressModal from "../../../components/tasks/scan_modals/progressModal";
    import rejectedModal from "@/components/tasks/scan_modals/rejectedModal";
    import successModal from "../../../components/tasks/scan_modals/successModal";
    import awaitingModal from "../../../components/tasks/scan_modals/awaitingModal";

    import companyconfig from "@/core/companyconfig";
    import api from "@/services/fetchapi";
    import receipts_api from "@/services/receiptapi";
    import auth from "@/core/auth";


    window.$ = window.jQuery = $;

    export default {
        data() {
            return {
                status: 0, // TODO: add status constants like in Receipt.php model, use them instead of numerics
                id: 0,
                task: [],
                tabs: "tab-1",
                config: {},
                scanErrorMessage: "",
                scanErrorExists: false,
                scanErrorType: "error",
                receiptProcessingResultStatus: '',
                correctionModalVisible: false,
                errorModalVisible: false,
                progressModalVisible: false,
                rejectedModalVisible: false,
                successModalVisible: false,
                image: '',
                storageUrl: '',
                imageUrl: '',
                nip: '',
                number: '',
                date: '',
                sum: '',
                is_matched: false,
                products: [],
                incentives: [],
                original_width: 0,
                original_height: 0,
                cancel_poll: 0
            };
        },
        components: {
            correctionModal,
            errorModal,
            progressModal,
            rejectedModal,
            successModal,
            awaitingModal
        },
        created() {
            this.id = 0;
            this.setupLayout();
            this.getTaskInfo();
        },
        methods: {
            closeAllModals() {
                this.status = 0
            },
            correctionModalHide () {
              this.status = 0
              this.id = 0
              // TODO: fire event that correction cancelled
            },
            correctionModalNext () {
              this.status = 0
              $('#file').click()
            },
            correctionModalMark () {
              this.status = -1
              const passedCompanyId = companyconfig.getCompanyIdfromUrl();
                let token = auth.getAccessToken();
                let self = this
                receipts_api
                    .mark(token, passedCompanyId, this.id)
                    .then(result => {
                        let response = result.data;
                        if (response.status == 200) {
                            this.status = 11;
                            this.id = 0
                        }  else {
                            this.status = 0
                            this.id = 0
                            console.log(response)
                            self.showErrorModal(this.$t('TaskScreen.MANUAL_CHECK_ERROR'))
                        }
                    })
                    .catch(err => {
                        this.status = 0
                        this.id = 0
                        self.showErrorModal(this.$t('TaskScreen.MANUAL_CHECK_ERROR'));
                        console.log(err)
                    });
            },
            correctionDone () {
                // TODO: call correction endpoint
            },
            errorModalHide () {
                this.scanErrorExists = false;
            },
            awaitingModalHide () {
                this.status = 0
                this.id = 0
            },
            progressModalHide () {
                self.cancel_poll = 1
                this.status = 0
                this.id = 0
            },
            rejectedModalHide () {
                this.status = 0
                this.id = 0
            },
            /* eslint-disable */
            successModalHide (productNum, incentiveId) {

                /*
                if (!this.is_matched) {
                    this.status = -1
                    const passedCompanyId = companyconfig.getCompanyIdfromUrl();
                    let token = auth.getAccessToken();
                    receipts_api
                        .match(token, passedCompanyId, this.id, productNum, incentiveId)
                        .then(result => {
                            let response = result.data;
                            if (response.status == 200) {
                                this.status = 1
                                this.is_matched = true
                                this.products = []
                                this.incentives = []
                            } else {
                                console.log(response)
                                // TODO: move to translated texts, translate to CZK
                                this.status = 0
                                this.id = 0
                                self.showErrorModal("Error while trying to save the information.")
                            }
                        })
                        .catch(err => {
                            let errormsg = err.data.message;
                            console.log(errormsg)
                            this.status = 0
                            this.id = 0
                            self.showErrorModal("Error while trying to save the information.")
                        });
                } else {
                    this.status = 0
                    this.id = 0
                }*/
                this.status = 0
                this.id = 0
            },
            /* eslint-enable */
            /**
             * Get the task details based on the task id
             */
            getTaskInfo() {
                const passedCompanyId = companyconfig.getCompanyIdfromUrl();
                let token = auth.getAccessToken();
                api
                    .getTaskById(token, passedCompanyId, 8) //TODO: Hardcoded TARAS
                    .then(result => {
                        let response = result.data;
                        if (response.data) {
                            this.task = response.data;
                        }
                    })
                    .catch(err => {
                        this.console.log(err);
                    });
            },
            /** Navigate to previous url */
            navigate() {
                this.$router.go(-1);
            },
            /** Navigate to home page */
            navigatehome() {
                this.$router.push({
                    name: "home"
                });
            },
            /**
             * Set the Intial color configuration for page
             */
            setupLayout() {
                let data = companyconfig.getCompanyScheme();
                if (data != "") {
                    this.$set(this.config, "mcolor", data.main_color);
                    this.$set(this.config, "mfontcolor", data.main_font_color);
                }
            },
            choose () {
                console.log('> Choose clicked.');
                $('#file').click()
            },
            onFileChange (e) {
                let files = e.target.files || e.dataTransfer.files
                if (!files.length) return
                this.status = -3
                this.createImage(files[0])
            },
            createImage (file) {
                let reader = new FileReader()
                reader.onload = (e) => {
                    console.log('length: ', e.target.result.includes('data:image/jpeg'))
                    if (!e.target.result.includes('data:image/jpeg')) {
                        this.status = 0
                        this.showErrorModal(this.$t('TaskScreen.SCAN_WRONG_FORMAT'));
                        return
                    }
                    if (e.target.result.length > MAX_IMAGE_SIZE) {
                        this.status = 0
                        this.showErrorModal(this.$t('TaskScreen.SCAN_IMAGE_TOO_MUCH'));
                        return
                    }
                    this.image = e.target.result
                }
                reader.readAsDataURL(file)
                this.uploadFile ()
            },
            hideErrorModal () {
                this.scanErrorExists = false;
            },
            showErrorModal (text) {
                this.scanErrorExists = true;
                this.scanErrorType = "error";
                this.scanErrorMessage = text
            },
            showInfoModal (text) {
                this.scanErrorExists = true;
                this.scanErrorType = "info";
                this.scanErrorMessage = text
            },
            uploadFile () {
                const passedCompanyId = companyconfig.getCompanyIdfromUrl();
                let token = auth.getAccessToken();
                let self = this
                receipts_api
                    .storage(token, passedCompanyId, this.id)
                    .then(result => {
                        let response = result.data;
                        if (response.status == 200) {
                            // OK
                            self.id = response.rid
                            self.storageUrl = response.storage_url
                            let binary = atob(self.image.split(',')[1])
                            let array = []
                            for (var i = 0; i < binary.length; i++) {
                                array.push(binary.charCodeAt(i))
                            }
                            let blobData = new Blob([new Uint8Array(array)], {type: 'image/jpeg'})
                            console.log('Uploading to: ', self.storageUrl)
                            let dofetch = async function() {
                                const result = await fetch(self.storageUrl, {
                                        method: 'PUT',
                                        body: blobData
                                    }
                                );
                                console.log(result)
                                self.startProcessing()
                            }
                            dofetch()
                        } else if (response.status == 409) {
                            this.status = 0
                            this.id = 0
                            self.showErrorModal(this.$t('TaskScreen.SCAN_ERROR_NO_COUPONES'))
                        } else {
                            console.log(response)
                            this.status = 0
                            this.id = 0
                            // TODO: move to translated texts, translate to CZK
                            self.showErrorModal(this.$t('TaskScreen.SCAN_ERROR_UPLOAD'))
                        }
                    })
                    .catch(err => {
                        console.log(err)
                        //let errormsg = err.data.message;
                        //console.log(errormsg)
                        //self.showError("Error while uploading.")
                    });
            },
            startProcessing () {
                const passedCompanyId = companyconfig.getCompanyIdfromUrl();
                let token = auth.getAccessToken();
                let self = this
                receipts_api
                    .process(token, passedCompanyId, this.id)
                    .then(result => {
                        let response = result.data;
                        if (response.status == 200) {
                            console.log('processing started')
                            self.waitWithPoll()
                        }  else {
                            console.log(response)
                            this.status = 0
                            self.showErrorModal(this.$t('TaskScreen.SCAN_ERROR_CANT_START'))
                        }
                    })
                    .catch(err => {
                        //let errormsg = err.data.message;
                        //console.log(errormsg)
                        //self.showError("Error while trying to start processing.")
                        console.log(err)
                    });
            },
            waitWithPoll () {
                const passedCompanyId = companyconfig.getCompanyIdfromUrl();
                let token = auth.getAccessToken();
                let self = this
                receipts_api
                    .poll(token, passedCompanyId, this.id)
                    .then(result => {
                        let response = result.data;
                        if (self.cancel_poll > 0) {
                            console.log('Cancelled poll.')
                            self.cancel_poll = 0;
                        }
                        if (response.status == 200) {
                            console.log('received poll result')
                            console.log(response)
                            self.status = parseInt(response.s)
                            self.receiptProcessingResultStatus = response.inf
                            self.nip = response.nip
                            self.number = response.number
                            self.date = response.date
                            self.sum = response.sum
                            self.is_matched = response.matched
                            self.products = response.products
                            self.incentives = response.availed_incentives
                            self.original_width = response.original_width
                            self.original_height = response.original_height
                            if (self.status < 0) {
                                setTimeout(self.waitWithPoll, 500);
                            }
                        }  else {
                            console.log(response)
                            // TODO: move to translated texts, translate to CZK
                            this.status = 0
                            self.showErrorModal(this.$t('TaskScreen.SCAN_ERROR_NO_RESULT'))
                        }
                    })
                    .catch(err => {
                        //let errormsg = err.data.message;
                        //console.log(errormsg)
                        //self.showError("Error while trying to check the result.")
                        this.status = 0
                        console.log(err)
                    });
            },
            showReceiptProcessing () {
                this.closeAllModals();
                this.status = -1;
                this.progressModalVisible = true;
            },
            showReceiptError_nonretriable (status) {
                this.closeAllModals();
                this.receiptProcessingResultStatus = status;
                this.rejectedModalVisible = true;
            },
        },
        mounted() {

        }
    };
</script>
