import Vue from "vue";
import VueRouter from "vue-router";
import appauth from "@/core/auth.js";
import rules from "@/views/home/companyrules";
import companyconfig from "@/core/companyconfig";
import signinorregister from "@/views/signinorregister";
import sociallogin from "@/views/sociallogin";
import simplelogin from "@/views/simplelogin";
import forgotpassword from "@/views/forgotpassword";
import notFound from "@/components/shared/notFound";
import welcome from "@/views/welcome";
import layout from "@/views/home/layout";
import home from "@/views/home/home";
import reward from "@/views/home/reward/rewarddetails";
import buyreward from "@/views/home/reward/purchasereward";
import scan from "@/views/home/tasks/scancode";
import receipt from "../views/home/tasks/receipt";
import invite from "@/views/home/tasks/invitefriend";
import feedback from "@/views/home/tasks/feedback";
import history from "@/views/history";
import { loadedLanguages } from "@/plugins/i18n";
import cashback from "../views/home/cashback/cashback";
import signupsms from "../views/signupsms";
import forgotphonepassword from "../views/forgotphonepassword";
import unsupportedBrowser from "@/components/unsupportedBrowser";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    component: notFound
  },

  {
    path: "/",
    name: "welcome",
    component: welcome
  },
  {
    path: "/login",
    name: "login",
    component: sociallogin
  },
  {
    path: "/simplelogin",
    name: "simplelogin",
    component: simplelogin
  },
  {
    path: "/signup",
    name: "signup",
    component: signinorregister
  },
  {
    path: "/signupsms",
    name: "signupsms",
    component: signupsms
  },
  {
    path: "/forgotpassword",
    name: "forgotpassword",
    component: forgotpassword
  },
  {
    path: "/forgotphonepassword",
    name: "forgotphonepassword",
    component: forgotphonepassword
  },
  {
    path: "/history",
    name: "history",
    component: history
  },
  {
    path: '/browser-not-supported',
    name: 'unsupportedBrowser',
    component: unsupportedBrowser
  },
  {
    path: "/home",
    name: "homelayout",
    component: layout,
    children: [
      {
        path: "",
        name: "rules",
        component: rules
      },
      {
        path: "/index",
        name: "home",
        component: home
      },
      {
        path: "/rewards/:type/:id",
        name: "rewardinfo",
        component: reward
      },
      {
        path: "/buyrewards/:id",
        name: "buyreward",
        component: buyreward
      },
      {
        path: "/feedback/:id",
        name: "feedback",
        component: feedback
      },
      {
        path: "/invite/:id",
        name: "invite",
        component: invite
      },
      {
        path: "/scan/:id",
        name: "scan",
        component: scan
      },
      {
        path: "/receipt/",
        name: "receipt",
        component: receipt
      },
      {
        path: "/cashback",
        name: 'cashback',
        component: cashback
      }
    ],
    meta: {
      requiresAuth: true
    }
  }
];

const router = new VueRouter({
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    };
  },
  mode: "history",
  routes
});

function hasParam(query, name) {
  return Object.keys(query).indexOf(name) >= 0 && query[name].length > 0
}

function runNextParamSafe (to, next) {
  let passedCompanyId = companyconfig.getCompanyIdfromUrl();
  let passedDeviceId = companyconfig.getDeviceIdfromUrl();
  let is_legacy = companyconfig.isLegacyMode (passedCompanyId)
  let query = Object.assign({}, to.query); // clone
  if (!hasParam(query, 'company_name')) query.company_name = passedCompanyId;
  if (!hasParam(query, 'd')) query.d = passedDeviceId;
  if (!hasParam(query, 'legacy') && is_legacy) query.legacy = 'true';
  let toWithQuery = Object.assign({}, to, {query: query}); // clone with overwrite
  next(toWithQuery);
}

router.beforeEach((to, from, next) => {
  const loggedIn = appauth.isLoggedIn();
  const hasLang = loadedLanguages.length > 0
  const isCompanySchemeSet = companyconfig.isCompanySchemeSet()
  const doRequireAuth = to.matched.some(record => record.meta.requiresAuth)
  const passedCompanyId = companyconfig.getCompanyIdfromUrl();
  const is_legacy = companyconfig.isLegacyMode (passedCompanyId)
  const shouldGoToWelcome = !isCompanySchemeSet || !hasLang || (doRequireAuth && !loggedIn)
  const hasNeededParams = hasParam(to.query, 'company_name') && hasParam(to.query, 'd') && (!is_legacy || hasParam(to.query, 'legacy'))
  if (to.matched.length > 0 && shouldGoToWelcome && to.name != "welcome") {
    runNextParamSafe ({name: "welcome"}, next)
  } else if (hasNeededParams) {
    next()
  } else {
    runNextParamSafe (to, next)
  }
});

export default router;
