<template>
  <v-card flat tile>
    <v-toolbar
      flat
      dense
      :color="config.mcolor"
      :style="`color:${config.mfontcolor} !important`"
    >
      <v-btn
        icon
        class="border0px"
        @click="navigate"
        :style="`color:${config.mfontcolor} !important`"
      >
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title
        v-text="$t('RewardsScreen.BUY') + ' ' + product.name"
      ></v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        icon
        class="border0px"
        @click="navigatehome"
        :style="`color:${config.mfontcolor} !important`"
      >
        <v-icon>mdi-home-circle-outline</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card flat v-if="!showSuccess">
      <v-card-text>
        <v-row>
          <v-col class="col-12">
            <v-form v-model="address.valid" ref="addrForm">
              <v-text-field
                autocomplete="off"
                filled
                dense
                :label="$t('RewardsScreen.NAME')"
                name="full_name"
                prepend-icon="person"
                type="text"
                autofocus
                v-model="address.full_name"
                :rules="[
                  value => {
                    return purchasefullnamerule(value);
                  }
                ]"
              ></v-text-field>
              <v-row no-gutters>
                <v-col xs6>
                  <v-text-field
                    autocomplete="off"
                    filled
                    dense
                    :label="$t('RewardsScreen.APARTMENTNO')"
                    name="addrAprtment"
                    prepend-icon="mdi-home-floor-0"
                    type="text"
                    v-model="address.apartment_number"
                  ></v-text-field>
                </v-col>
                <v-col xs6>
                  <v-text-field
                    autocomplete="off"
                    filled
                    dense
                    :label="$t('RewardsScreen.STREETNO')"
                    name="addrStreet"
                    prepend-icon="mdi-home-account"
                    type="text"
                    v-model="address.street_number"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-textarea
                dense
                filled
                rows="2"
                clear-icon="cancel"
                :label="$t('RewardsScreen.ADDRESS')"
                name="address"
                v-model="address.street_address"
                prepend-icon="mdi-home-letiant"
              ></v-textarea>

              <v-text-field
                autocomplete="off"
                filled
                dense
                :label="$t('RewardsScreen.CITY')"
                name="addrCity"
                prepend-icon="mdi-home-city"
                type="text"
                v-model="address.city"
              ></v-text-field>
              <v-text-field
                autocomplete="off"
                filled
                dense
                :label="$t('RewardsScreen.COUNTRY')"
                name="addrProvince"
                prepend-icon="mdi-globe-model"
                type="text"
                v-model="address.country"
              ></v-text-field>
              <v-text-field
                autocomplete="off"
                filled
                dense
                :label="$t('RewardsScreen.ZIPCODE')"
                name="addrZip"
                prepend-icon="mdi-home-map-marker"
                type="text"
                v-model="address.zip_code"
                :rules="[
                  value => {
                    return purchasezipcoderule(value);
                  }
                ]"
              ></v-text-field>

              <alert
                :errorType="address.errtype"
                :errorMessage="address.errors"
                :alert="address.showerror"
              ></alert>
            </v-form>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          block
          :color="config.mcolor"
          :style="`color:${config.mfontcolor} !important`"
          :loading="address.loading"
          :disabled="address.loading"
          @click="doPurchase"
          v-text="$t('RewardsScreen.PURCHASE_BTN')"
        ></v-btn>
      </v-card-actions>
    </v-card>
    <v-layout align-center justify-center v-if="showSuccess" class="mt-12">
      <v-card elevated raised width="350" class="text-center pt-10">
        <v-icon height="150px" size="64" color="green"
          >mdi-check-circle-outline</v-icon
        >
        <v-card-title
          v-text="$t('RewardsScreen.PURCHASE_SUCCESSFUL')"
          class="font-weight-bold justify-center"
        ></v-card-title>
        <v-card-actions>
          <v-btn
            block
            :color="config.mcolor"
            :style="`color:${config.mfontcolor} !important`"
            rounded
            class="mt-12"
            v-text="$t('RewardsScreen.GOTO_HOME')"
            @click="navigatehome"
          ></v-btn>
        </v-card-actions>
      </v-card>
    </v-layout>
  </v-card>
</template>
<script>
import {
  NO_COMPANY_FOUND,
  CREATED_PURCHASE_SUCCESSFULLY,
  UNABLE_TO_CREATE_PURCHASE,
  PRODUCT_ID_IS_REQUIRED,
  COMPANY_URL_IS_REQUIRED,
  INSUFFICIENT_POINT,
  PRODUCT_DOES_NOT_BELONG_TO_THIS_COMPANY,
  NO_PRODUCT_FOUND,
  PURCHASE_LIMIT_EXCEEDED,
  USER_DONT_BELONG_TO_THIS_COMPANY
} from "@/appConstants";
import companyconfig from "@/core/companyconfig";
import api from "@/services/fetchapi";
import auth from "@/core/auth";
import alert from "@/components/shared/erroralert";
export default {
  data() {
    return {
      tabaddr: "",
      product: {
        id: 0,
        name: "",
        description: "",
        picture: "",
        price: 0,
        is_virtual: false
      },
      config: {},
      showSuccess: false,
      address: {
        full_name: "",
        street_number: "",
        apartment_number: "",
        street_address: "",
        city: "",
        country: "",
        zip_code: "",
        errtype: "error",
        errors: "",
        showerror: false,
        loading: false
      }
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.setupLayout();
    this.loadAddress();
    this.loadReward();
  },
  methods: {
    /**
     * Set the Intial color configuration for page
     */
    setupLayout() {
      let data = companyconfig.getCompanyScheme();
      if (data != "") {
        this.$set(this.config, "mcolor", data.main_color);
        this.$set(this.config, "mfontcolor", data.main_font_color);
        this.$set(this.config, "pcolor", data.primary_color);
        this.$set(this.config, "pfontcolor", data.primary_font_color);
      }
    },
    /**
     * Submit the user details for purchase
     */
    doPurchase() {
      let isvalid = this.$refs.addrForm.validate();
      let passedCompanyId = companyconfig.getCompanyIdfromUrl();
      if (isvalid) {
        let token = auth.getAccessToken();
        this.address.showerror = false;
        this.address.loading = true;
        let self = this;
        api
          .purchaseReward(
            token,
            passedCompanyId,
            this.product.name,
            this.address.name,
            this.address.street_number,
            this.address.apartment_number,
            this.address.street_address,
            this.address.zip_code,
            this.address.city,
            this.address.country,
            this.id
          )
          .then(function(response) {
            let msg = response.data.message;
            if (msg === CREATED_PURCHASE_SUCCESSFULLY) {
              self.showSuccess = true;
            } else {
              self.showSuccess = false;
              self.address.errors = self.$t("Errors.FAILED_TO_PURCHASE");
              self.address.showerror = true;
              self.$refs.addrForm.name.focus();
            }
            self.address.loading = false;
          })
          .catch(function(error) {
            let errmsg = error.data.message;
            if (errmsg === UNABLE_TO_CREATE_PURCHASE) {
              let errObj = error.data.error;
              if (
                errObj.product_id &&
                errObj.product_id[0] === PRODUCT_ID_IS_REQUIRED
              ) {
                self.address.errors = self.$t("Errors.PRODUCT_ID_IS_REQUIRED");
              } else if (
                errObj.url_name &&
                errObj.url_name[0] === COMPANY_URL_IS_REQUIRED
              ) {
                self.address.errors = self.$t("Errors.COMPANY_URL_IS_REQUIRED");
              } else if (
                errObj.money &&
                errObj.money[0] === INSUFFICIENT_POINT
              ) {
                self.address.errors = self.$t("Errors.INSUFFICIENT_POINT");
              } else if (
                errObj.url_name &&
                errObj.url_name[0] === NO_COMPANY_FOUND
              ) {
                self.address.errors = self.$t("Errors.NO_COMPANY_FOUND");
              } else if (
                errObj.product_id &&
                errObj.product_id[0] == PRODUCT_DOES_NOT_BELONG_TO_THIS_COMPANY
              ) {
                self.address.errors = self.$t(
                  "Errors.PRODUCT_DOES_NOT_BELONG_TO_THIS_COMPANY"
                );
              } else if (
                errObj.product_id &&
                errObj.product_id[0] === NO_PRODUCT_FOUND
              ) {
                self.address.errors = self.$t("Errors.NO_PRODUCT_FOUND");
              } else if (
                errObj.purchase &&
                errObj.purchase[0] === PURCHASE_LIMIT_EXCEEDED
              ) {
                self.address.errors = self.$t("Errors.PURCHASE_LIMIT_EXCEEDED");
              } else if (
                errObj.money &&
                errObj.money[0] === USER_DONT_BELONG_TO_THIS_COMPANY
              ) {
                self.address.errors = self.$t(
                  "Errors.USER_DONT_BELONG_TO_THIS_COMPANY"
                );
              }
            } else {
              self.address.errors = self.$t("Errors.FAILED_TO_PURCHASE");
            }
            self.showSuccess = false;
            self.address.showerror = true;
            self.address.loading = false;
          });
      }
    },
    /** Purchase Name Mandatory rule */
    purchasefullnamerule(value) {
      if (!value) {
        return this.$t("RewardsScreen.NAME_REQUIRED");
      }
      return true;
    },
    /** User zipcode mandatory rule */
    purchasezipcoderule(value) {
      if (!value) {
        return this.$t("RewardsScreen.ZIPCODE_REQUIRED");
      }
      return true;
    },
    /**
     * Load Reward Name
     */
    loadReward() {
      let passedCompanyId = companyconfig.getCompanyIdfromUrl();
      let token = auth.getAccessToken();
      let lang = this.$i18n.locale;
      api
        .getReward(token, passedCompanyId, lang, this.id)
        .then(result => {
          let response = result.data;
          if (response.data) {
            let data = response.data[0];
            this.product = data;
          }
        })
        .catch(err => {
          let errmsg = err.data.message;
          if (errmsg === NO_COMPANY_FOUND) {
            console.log(err);
          }
        });
    },
    /**
     * Load the user address details
     */
    loadAddress() {
      let passedCompanyId = companyconfig.getCompanyIdfromUrl();
      let token = auth.getAccessToken();
      api
        .getUserAddress(token, passedCompanyId)
        .then(result => {
          let response = result.data;
          if (response.data) {
            let data = response.data;
            this.address.full_name = data.full_name;
            this.address.street_number = data.street_number;
            this.address.apartment_number = data.apartment_number;
            this.address.street_address = data.street_address;
            this.address.city = data.city;
            this.address.country = data.country;
            this.address.zip_code = data.zip_code;
          }
        })
        .catch(err => {
          let errmsg = err.data.message;
          if (errmsg === NO_COMPANY_FOUND) {
            console.log(err);
          }
        });
    },
    /**
     * Navigate tp previous url
     */
    navigate() {
      this.$router.go(-1);
    },
    /**
     * Navigate to home page
     */
    navigatehome() {
      this.$router.push({
        name: "home"
      });
    }
  },
  components: {
    alert
  }
};
</script>
