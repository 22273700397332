<template>
    <v-dialog
            v-model="childVisible"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition">
        <v-card class="modelFullscreen" style="text-align: center" align="center">
            <div class="pt-12">
                <v-img
                        :src="config.shortlogo"
                        max-height="80px"
                        contain
                        v-if="config.showLogo"
                ></v-img>
            </div>
            <div class="pt-12">
                <h5 class="modalHeader">{{$t('TaskScreen.MODAL_PROGRESS_TITLE')}}</h5>
            </div>
            <div class="pt-12">
                <p class="">{{$t('TaskScreen.MODAL_PROGRESS_SUBTITLE')}}</p>
            </div>
            <div class="pt-8 pl-5 pr-5">
                <p class="modalInfoText" v-if="randomQuoteNumber === 0">{{$t('TaskScreen.MODAL_PROGRESS_SUGGESTION_1')}}</p>
                <p class="modalInfoText" v-if="randomQuoteNumber === 1">{{$t('TaskScreen.MODAL_PROGRESS_SUGGESTION_2')}}</p>
                <p class="modalInfoText" v-if="randomQuoteNumber === 2">{{$t('TaskScreen.MODAL_PROGRESS_SUGGESTION_3')}}</p>
            </div>

            <div class="pt-2">
                <div v-if="randomQuoteNumber === 0">
                    <img src="../../../assets/images/receipt/World_01.png" class="progressModalImage"/>
                </div>
                <div v-if="randomQuoteNumber === 1">
                    <img src="../../../assets/images/receipt/World_01.png" class="progressModalImage"/>
                </div>
                <div v-if="randomQuoteNumber === 2">
                    <img src="../../../assets/images/receipt/Scan_01.png" class="progressModalImage"/>
                </div>
            </div>

            <div class="pl-12 pr-12">
                <v-progress-linear
                        color="deep-purple accent-4"
                        indeterminate
                        rounded
                        height="6"
                ></v-progress-linear>
            </div>

            <v-btn @click="hide" class="mt-12">
                {{$t('TaskScreen.SCAN_CLOSE')}}
            </v-btn>
        </v-card>
    </v-dialog>
</template>
<script>
    import companyconfig from "@/core/companyconfig";
    export default {
        data() {
            return {
                childVisible: true,
                config: {},
                randomQuoteNumber: 0,
            }
        },
        created() {
            this.setupLayout();
            this.randomQuoteNumber = this.randomInt(3)
        },
        methods: {
            hide () {
                this.$emit('progress-modal-hide')
            },
            setupLayout() {
                let data = companyconfig.getCompanyScheme();
                if (data != "") {
                    this.$set(this.config, "mcolor", data.main_color);
                    this.$set(this.config, "mfontcolor", data.main_font_color);
                    this.$set(this.config, "shortlogo", data.shortLogo);
                    this.$set(this.config, "showLogo", data.showLogo);
                    this.$set(this.config, "showMenu", data.showMenu);
                    this.$set(this.config, "logoPosition", data.logoPosition);
                }
            },
            randomInt(max) {
                return Math.floor(Math.random() * Math.floor(max));
            }
        }
    }
</script>
