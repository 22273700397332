<template>
  <div class="container">
    <div class="wrapper">
      <img class="status-page-image" src="../assets/browsers/hello.png" />

      <h1 class="not-supported-page-title">
        System lojalnościowy<br>nie jest dostępny
      </h1>

      <p class="not-supported-intro">
        Zauważyliśmy, że używasz wersji przeglądarki, która nie obsługuje wykonywania skryptów JavaScript lub ma włączony tryb Incognito.
        <br><br>
        Prosimy włączyć JavaScript, wyłączyć tryb Incognito lub zainstalować przeglądarki z poniższej listy.
      </p>

      <div class="browsers-container">
        <h2 class="supported-intro">Zalecani przeglądarki:</h2>

        <ul class="supported-browsers-list">
          <li class="supported-browsers-list-item browser-chrome">
            <div class="supported-browser-information">
              Google Chrome
            </div>
            <div class="supported-browser-download">
              <a href="https://www.google.com/intl/en/chrome" target="_blank" rel="noopener">
                Download latest
              </a>
            </div>
            <div class="clearfix"></div>
          </li>
          <li class="supported-browsers-list-item browser-firefox">
            <div class="supported-browser-information">
              Mozilla Firefox
            </div>
            <div class="supported-browser-download">
              <a href="https://www.mozilla.org/firefox/all/" target="_blank" rel="noopener">
                Download latest
              </a>
            </div>
            <div class="clearfix"></div>
          </li>
          <li class="supported-browsers-list-item browser-opera">
            <div class="supported-browser-information">
              Opera
            </div>
            <div class="supported-browser-download">
              <a href="https://www.opera.com/download" target="_blank" rel="noopener">
                Download latest
              </a>
            </div>
            <div class="clearfix"></div>
          </li>
          <li class="supported-browsers-list-item browser-ie">
            <div class="supported-browser-information">
              Microsoft Edge
            </div>
            <div class="supported-browser-download">
              <a href="https://www.microsoft.com/en-us/edge"
                 target="_blank"
                 rel="noopener">
                Download latest
              </a>
            </div>
            <div class="clearfix"></div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "unsupportedBrowser",
  data() {
    return {

    }
  },
  methods: {

  },
  created() {

  }
}
</script>

<style scoped>
.container {
  padding: 10px;
  background-color: #f3f3f3;
  height: 100%;
}
.wrapper {
  background-color: #FFF;
  border-radius: 10px;
  height: 100%;
  padding: 20px 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.status-page-image {
  width: 120px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.not-supported-page-title {
  font-size: 25px;
  text-align: center;
  padding-top: 20px;
}
.not-supported-intro {
  color: #505050;
  font-size: 14px;
  font-family: 'Roboto';
  padding: 20px;
}
.supported-intro {
  font-size: 18px;
}
.supported-browsers-list {
  background: #fff;
  border: 1px solid #c5c5c5;
  margin-top: 30px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
}
.supported-browsers-list-item {
  padding: 30px 30px 30px 85px;
  border-bottom: 1px solid #dcdcdc;
}
.browser-ie {
  background: url(../assets/browsers/edge.png) no-repeat 30px 50%;
}
.browser-opera {
  background: url(../assets/browsers/opera.png) no-repeat 30px 50%;
}
.browser-firefox {
  background: url(../assets/browsers/firefox.png) no-repeat 30px 50%;
}
.browser-chrome {
  background: url(../assets/browsers/chrome.png) no-repeat 30px 50%;
}
</style>
