    let $2TI = {
       triggerWindow: function (id, event, args) {
            var message = event;
            if (args instanceof Array) {
                for (var n in args) message += '|'+args[n];
            }
            if (id === true || id === false || id === 0) {
                // to the parent
                window.parent.postMessage(message, '*');
            } else {
                // to iframe by id
                document.getElementById(id).contentWindow.postMessage(message,'*');
            }
        },

        trigger: function (eventName, args) {
            let element = document.querySelector('body')
            let event = new CustomEvent(eventName, { detail: args });
            element.dispatchEvent(event);
        },

        on: function (eventName, handler) {
            let element = document.querySelector('body')
            element.addEventListener(eventName, function(event) {
                handler(event, ...event.detail);
            });
        },

       messageDispatcher: function (data) {
            if (typeof data == 'undefined' || data.length == 0 || data === 'object') return;
            var data_str = data  == null || typeof data != 'string' ? '' : data;
            if (data_str.length == 0) return;
            var params = data_str.split('|');
            var event_name = params.shift();
            if (typeof window.console != undefined) {
                window.console.log('Event: '+event_name);
            }
            $2TI.trigger(event_name,params);
        },

        attachMessageDispatcher: function () {
            var eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
            var addEventListener = window[eventMethod];
            var messageEvent = eventMethod == "attachEvent" ? "onmessage" : "message";

            addEventListener(messageEvent,function(e) {
                var key = e.message ? "message" : "data";
                var data = e[key];
                $2TI.messageDispatcher(data);
            },false);
            // for case with child window instead of child/parent frame
            // use window.opener.messageFromWindow(message) there
            window.messageFromWindow = function (message) {
              $2TI.messageDispatcher(message);
            };
        },

        setupUTMHandler: function () {
            let utmHandler = function (e,utmString) {
                if (typeof window.dataLayer == 'undefined') {
                    setTimeout(() => {
                        $2TI.utmHandler(e, utmString);
                    },100)
                    return
                }
                let utmParams = JSON.parse(utmString)
                utmParams['event'] = 'widget-loaded'
                window.console.log('UTM parameters received:')
                window.console.log(utmParams)
                if (typeof window.gtag != 'undefined') {
                    window.gtag('set', utmParams);
                }
                window.dataLayer.push(utmParams)
            }
            $2TI.on('referrer-utm', utmHandler);
        }
    }

    export default $2TI