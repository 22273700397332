<template>
  <v-container>
    <div v-if="!notSupportedBrowser">
      <h1 v-text="$t('AppScreen.WELCOME')">Welcome the Loyalty App</h1>
      <v-btn :loading="!isLoaded" :block="true" :text="true">Loading ...</v-btn>
      <p
          v-show="isErrorCompany"
          v-text="$t('Errors.FAILED_TO_LOAD_COMPANY_ISSUE')"
      ></p>
      <p v-show="isError" v-text="$t('Errors.FAILED_TO_LOAD')"></p>
    </div>


    <div v-if="notSupportedBrowser">
      <div class="container">
        <div class="wrapper">
          <img class="status-page-image" src="../assets/browsers/hello.png" />

          <h1 class="not-supported-page-title">
            System lojalnościowy<br>nie jest dostępny
          </h1>

          <p class="not-supported-intro">
            Zauważyliśmy, że używasz wersji przeglądarki, która nie obsługuje wykonywania skryptów JavaScript lub ma włączony tryb Incognito.
            <br><br>
            Prosimy włączyć JavaScript, wyłączyć tryb Incognito lub zainstalować przeglądarki z poniższej listy.
          </p>

          <div class="browsers-container">
            <h2 class="supported-intro">Zalecani przeglądarki:</h2>

            <ul class="supported-browsers-list">
              <li class="supported-browsers-list-item browser-chrome">
                <div class="supported-browser-information">
                  Google Chrome
                </div>
                <div class="supported-browser-download">
                  <a href="https://www.google.com/intl/en/chrome" target="_blank" rel="noopener">
                    Pobierz najnowszą wersję
                  </a>
                </div>
                <div class="clearfix"></div>
              </li>
              <li class="supported-browsers-list-item browser-firefox">
                <div class="supported-browser-information">
                  Mozilla Firefox
                </div>
                <div class="supported-browser-download">
                  <a href="https://www.mozilla.org/firefox/all/" target="_blank" rel="noopener">
                    Pobierz najnowszą wersję
                  </a>
                </div>
                <div class="clearfix"></div>
              </li>
              <li class="supported-browsers-list-item browser-opera">
                <div class="supported-browser-information">
                  Opera
                </div>
                <div class="supported-browser-download">
                  <a href="https://www.opera.com/download" target="_blank" rel="noopener">
                    Pobierz najnowszą wersję
                  </a>
                </div>
                <div class="clearfix"></div>
              </li>
              <li class="supported-browsers-list-item browser-ie">
                <div class="supported-browser-information">
                  Microsoft Edge
                </div>
                <div class="supported-browser-download">
                  <a href="https://www.microsoft.com/en-us/edge"
                     target="_blank"
                     rel="noopener">
                    Pobierz najnowszą wersję
                  </a>
                </div>
                <div class="clearfix"></div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>
<script>
import api from "@/services/companyapi";
import fapi from "@/services/fetchapi";
import auth from "@/core/auth.js";
import companyconfig from "@/core/companyconfig";
// import config from "@/variable"; // unused
import { loadLanguageAsync } from "@/plugins/i18n";
import Vue from "vue";
export default {
  data() {
    return {
      isLoaded: false,
      isError: false,
      isErrorCompany: false,
      notSupportedBrowser: false
    };
  },
  methods: {
    /** Load the company config and save to localstorage  */
    processCompany() {
      const passedCompanyId = companyconfig.getCompanyIdfromUrl();
      let isLegacy = false;
      if (this.$route.query.legacy) {
        companyconfig.setLoginLegacyMode(passedCompanyId);
        isLegacy = true;
      }
      if (this.$route.query.rc) {
        companyconfig.setInvitationCode(passedCompanyId, this.$route.query.rc);
      }
      api
        .getCompanyInfo(passedCompanyId, isLegacy)
        .then(result => {
          companyconfig.setCompanyScheme(result.data, passedCompanyId);
          if (result.data.data.company_id) {
            Vue.localStorage.set("company_id", result.data.data.company_id);
            sessionStorage.setItem('vuels__2tiCompanyId', result.data.data.company_id);
          }
          this.isLoaded = true;
          this.doRedirect();
        })
        .catch(err => {
          console.log(err);
          var msg = '';
          if (typeof err.data != 'undefined' && typeof err.data.message != 'undefined') {
            msg = err.data.message;
          }
          if (msg === "NO_COMPANY_FOUND") {
            this.isError = false;
            this.isErrorCompany = true;
          } else {
            this.isError = true;
            this.isErrorCompany = false;
          }
        });
    },
    /** Check company name changed in same tab */
    checkCompanyChanged(callback) {
      // let passedCompanyId = companyconfig.getCompanyIdfromUrl();
      // let companyId = companyconfig.getCompanyId();
      // if (companyId === "" || passedCompanyId != companyId) {
      //   companyconfig.resetCompanyLocals(companyId);
      //   this.processCompany();
      //   callback();
      // } else {
      //   callback();
      // }
      this.processCompany();
      callback();
    },
    /** Do the redirect either if logged in */
    doRedirect() {
      let islogged = auth.isLoggedIn();
      let currentroute = this.$route.name;
      let passedCompanyId = companyconfig.getCompanyIdfromUrl();
      let data = companyconfig.getCompanyScheme();

      if (!islogged) {
        if (currentroute !== "login") {
          if(data.use_simple_login && data.use_simple_login === '1') {
            this.$router.push({
              name: "simplelogin"
            });
          } else {
            this.$router.push({
              name: "login"
            });
          }
        }
      } else {
        if (
          currentroute === "welcome" ||
          currentroute === "login" ||
          currentroute === "signup" ||
          currentroute === "forgotpassword"
        ) {
          let token = auth.getAccessToken();
          let lang = this.$i18n.locale;
          if (fapi.hasAnyRules(token, passedCompanyId, lang)) {
            this.$router.push({
              name: "rules"
            });
          } else {
            this.$router.push({
              name: "home"
            });
          }
        } else {
          this.$router.push({
            name: currentroute
          });
        }
      }
    },
    /** Check the color and language configs are loaded */
    checkAuthenticatedWithConfigLoaded() {
      let isConfigLoaded = companyconfig.isCompanySchemeSet();
      let islogged = auth.isLoggedIn();
      if (islogged && isConfigLoaded) {
        this.doRedirect();
      } else if (!isConfigLoaded) {
        this.isLoaded = true;
        this.processCompany();
      } else if (isConfigLoaded) {
        this.isLoaded = true;
        this.doRedirect();
      }
    },
    /** Check company name is valid else show the error */
    checkValidCompany() {
      let passedCompanyId = companyconfig.getCompanyIdfromUrl();
      let self = this;
      let isLegacy = false;
      if (this.$route.query.legacy) {
        companyconfig.setLoginLegacyMode(passedCompanyId);
        isLegacy = true;
      }
      api
        .isValidCompany(passedCompanyId, isLegacy)
        .then(function(response) {
          if (response === true) {
            self.checkCompanyChanged(self.checkAuthenticatedWithConfigLoaded);
          } else {
            self.isLoaded = true;
            self.isError = false;
            self.isErrorCompany = true;
          }
        })
        .catch(() => {
          self.isLoaded = true;
          self.isError = false;
          self.isErrorCompany = true;
        });
    },
    /** Get the company name from url else show error */
    checkUrl(callback) {
      let passedCompanyId = companyconfig.getCompanyIdfromUrl();
      if (passedCompanyId === "") {
        this.isLoaded = true;
        this.isError = false;
        this.isErrorCompany = true;
      } else {
        callback();
      }
    },
    /** Load the default browser language strings and save in local storage */
    loadLanguageJSON(callback) {
      let lang = auth.getAppLanguage();
      let data = companyconfig.getCompanyScheme();

      let url_name = data.url_name;
      if (!url_name) {
        let url_string = window.location.href;
        let url = new URL(url_string);
        url_name = url.searchParams.get("company_name");
      }

      loadLanguageAsync(lang, url_name)
        .then(() => {
          callback(this.checkValidCompany);
        })
        .catch(err => {
          this.isLoaded = true;
          this.isError = false;
          console.log(err);
        });
    },
    /** Load the company based css files  */
    appendCompanyCss() {
      let ss = document.styleSheets;
      let passedCompanyId = companyconfig.getCompanyIdfromUrl();
      let companycss = passedCompanyId + ".css";
      for (let i = 0, max = ss.length; i < max; i++) {
        let href = ss[i].href;
        if (href && href.indexOf(companycss) > 0) return;
      }
      // let file = document.createElement("link");
      // file.rel = "stylesheet";
      // file.href = config.baseUrl + "/company_css/" + passedCompanyId + ".css";
      // document.head.appendChild(file);
      let cdata = companyconfig.getCompanyScheme();
      
      if(cdata.enable_chat != '1') {
          let chatcss = document.createElement("style");
          chatcss.innerHTML = '.sb-main { display: none};'
          document.head.appendChild(chatcss);
      }
    },
    isSupported(getStorage) {
      try {
        const key = "__some_random_key_you_are_not_going_to_use__";
        getStorage().setItem(key, key);
        getStorage().removeItem(key);
        return true;
      } catch (e) {
        // this.notSupportedBrowser = true;
      }
    },
    isSafari() {
       if (!/^((?!chrome|android).)*safari/i.test(navigator.userAgent)){
         // this.notSupportedBrowser = true;
       }
    }
  },
  created() {
    this.appendCompanyCss();
    this.loadLanguageJSON(this.checkUrl);
  },
  mounted() {
    this.isSupported();
  },
};
</script>


<style scoped>
.container {
  padding: 10px;
  background-color: #f3f3f3;
  height: 100%;
}
.wrapper {
  background-color: #FFF;
  border-radius: 10px;
  height: 100%;
  padding: 20px 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.status-page-image {
  width: 120px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.not-supported-page-title {
  font-size: 25px;
  text-align: center;
  padding-top: 20px;
}
.not-supported-intro {
  color: #505050;
  font-size: 14px;
  font-family: 'Roboto';
  padding: 20px;
}
.supported-intro {
  font-size: 18px;
}
.supported-browsers-list {
  background: #fff;
  border: 1px solid #c5c5c5;
  margin-top: 30px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
}
.supported-browsers-list-item {
  padding: 30px 30px 30px 85px;
  border-bottom: 1px solid #dcdcdc;
}
.browser-ie {
  background: url(../assets/browsers/edge.png) no-repeat 30px 50%;
}
.browser-opera {
  background: url(../assets/browsers/opera.png) no-repeat 30px 50%;
}
.browser-firefox {
  background: url(../assets/browsers/firefox.png) no-repeat 30px 50%;
}
.browser-chrome {
  background: url(../assets/browsers/chrome.png) no-repeat 30px 50%;
}
li.supported-browsers-list-item {
  list-style: none;
}
</style>
