export const REFRESH_TOKEN = "id_token";
export const ACCESS_TOKEN_KEY = "access_token";
export const ACCESS_EXPIRATION = "expirationtime";
export const COMPANY_CONFIG_KEY = "companyconfig";
export const USER_CONFIG_KEY = "userconfig"
export const COMPANY_LANGUAGE_KEY = "appLang";
export const LOGGED_USER = "user";
export const INVALID_ACCESS_TOKEN = "INVALID_ACCESS_TOKEN";
export const ACCESS_TOKEN_IS_REQUIRED = "ACCESS_TOKEN_IS_REQUIRED";
export const COMPANY_URL_IS_REQUIRED = "COMPANY_URL_IS_REQUIRED";
export const NO_COMPANY_FOUND = "NO_COMPANY_FOUND";
export const NO_COMPANY_TASK_FOUND = "NO_COMPANY_TASK_FOUND";
export const NO_INVITE_CODE = "NO_INVITE_CODE";
export const NO_BARCODE_SCANNED = "NO_BARCODE_SCANNED";
export const NO_SURVEY_DONE = "NO_SURVEY_DONE";
export const NO_COMPANY_TASK_DETAILS_FOUND = "NO_COMPANY_TASK_DETAILS_FOUND";

export const CREATED_PURCHASE_SUCCESSFULLY = "CREATED_PURCHASE_SUCCESSFULLY";
export const UNABLE_TO_CREATE_PURCHASE = "UNABLE_TO_CREATE_PURCHASE";
export const PRODUCT_ID_IS_REQUIRED = "PRODUCT_ID_IS_REQUIRED";
export const INSUFFICIENT_POINT = "INSUFFICIENT_POINT";
export const PRODUCT_DOES_NOT_BELONG_TO_THIS_COMPANY =
  "PRODUCT_DOES_NOT_BELONG_TO_THIS_COMPANY";
export const NO_PRODUCT_FOUND = "NO_PRODUCT_FOUND";
export const PURCHASE_LIMIT_EXCEEDED = "PURCHASE_LIMIT_EXCEEDED";
export const USER_DONT_BELONG_TO_THIS_COMPANY =
  "USER_DONT_BELONG_TO_THIS_COMPANY";

export const SURVEY_COMPLETED_SUCESSFULLY = "SURVEY_COMPLETED_SUCESSFULLY";
export const INVALID_DATA_SUBMITTED = "INVALID_DATA_SUBMITTED";
export const INVALID_QUESTION_ID = "INVALID_QUESTION_ID";
export const SURVEY_COMPLETED_ALREADY = "SURVEY_COMPLETED_ALREADY";
export const NO_QUESTIONS = "NO_QUESTIONS";

export const BARCODE_ALREADY_USED = "BARCODE_ALREADY_USED";
export const YOU_HAVE_SCANNED_THIS_BARCODE_ALREADY =
  "YOU_HAVE_SCANNED_THIS_BARCODE_ALREADY";
export const PASSWORD_RESET_SUCCESS = "PASSWORD_RESET_SUCCESS";
export const NO_HISTORY = "NO_HISTORY";

export const SURVEY = "SURV";
export const INVITE = "REG";
export const QR_SCAN = "QRSCAN";
export const BILL = "BILL";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const INCORRECT_EMAIL_FORMAT = "INCORRECT_EMAIL_FORMAT";
export const PASSWORD_IS_REQUIRED = "PASSWORD_IS_REQUIRED";
export const INCORRECT_EMAIL_OR_PASSWORD = "INCORRECT_EMAIL_OR_PASSWORD";
export const CREATED_ACCOUNT_SUCCESSFULLY = "CREATED_ACCOUNT_SUCCESSFULLY";
export const SMS_SENT = "SMS_SENT";
export const UNABLE_TO_VALIDATE_ACCOUNT = "UNABLE_TO_VALIDATE_ACCOUNT";
export const UNABLE_TO_CREATE_ACCOUNT = "UNABLE_TO_CREATE_ACCOUNT";
export const EMAIL_IS_REQUIRED = "EMAIL_IS_REQUIRED";
export const NAME_IS_REQUIRED = "NAME_IS_REQUIRED";
export const ACCOUNT_ALREADY_EXISTS = "ACCOUNT_ALREADY_EXISTS";
export const INVALID_REFRESH_TOKEN = "INVALID_REFRESH_TOKEN";

export const CASHBACK_ERROR_SUBMIT = "CASHBACK_ERROR_SUBMIT";
export const CASHBACK_WRONG_BANK_ACCOUNT = "CASHBACK_WRONG_BANK_ACCOUNT";
export const CASHBACK_WRONG_NAME = "CASHBACK_WRONG_NAME";
export const CASHBACK_WRONG_SUMM = "CASHBACK_WRONG_SUMM";
export const REQUEST_SUCCESSFULL = "REQUEST_SUCCESSFULL";
export const CASHBACK_REQUEST_BTN = "CASHBACK_REQUEST_BTN";
export const BANK_ACCOUNT = "BANK_ACCOUNT";
export const CASHBACK_NAME = "CASHBACK_NAME";
export const BLOVLY_COMPANY_ID = "337";
export const BLOVLY_DEV_COMPANY_ID = "309";

