import Vue from "vue";
import { COMPANY_CONFIG_KEY, COMPANY_LANGUAGE_KEY, USER_CONFIG_KEY } from "@/appConstants";

export default new (class companyconfig {
  /**
   * Remove the local storage for company
   * @param {string} company_name
   */
  resetCompanyLocals(company_name) {
    Vue.localStorage.remove(COMPANY_CONFIG_KEY + "_" + company_name);
    Vue.localStorage.remove(COMPANY_LANGUAGE_KEY + "_" + company_name);
    Vue.localStorage.remove(USER_CONFIG_KEY + "_" + company_name);
    Vue.localStorage.remove('system_user_id'); //NEEDED FOR "SUPPORTBOARD" CHAT SYNCHRONIZATION
    Vue.localStorage.remove('company_id'); //NEEDED FOR "SUPPORTBOARD" CHAT SYNCHRONIZATION
  }

  /**
   * Check whether email login is allowed to redirect from routing.
   */
  allowEmailLoginOnly() {
    let json = this.getCompanyScheme();
    if (json !== "") {
      return json.only_email_login;
    }
    return false;
  }
  /**
   * Get the company color configuration which is used in entire app.
   */
  getCompanyScheme() {
    let result = "";
    let params = this.getCompanyIdfromUrl();
    if (params != "") {
      let json = Vue.localStorage.get(COMPANY_CONFIG_KEY + "_" + params);
      if (json && json !== 'undefined') {
        result = JSON.parse(json);
      }
    }
    return result;
  }

  waitForCompanyScheme (callback) {
    let scheme = this.getCompanyScheme()
    if (scheme) {
      callback(scheme)
      return;
    }
    let c = callback, t = this
    setTimeout(() => {t.waitForCompanyScheme(c)},100);
  }

  /**
   * Get user config
   * @param companyName
   * @returns {string}
   */
  getUserScheme(companyName) {
    let result = "";
    let json = Vue.localStorage.get(USER_CONFIG_KEY + "_" + companyName);
    if (json) {
      result = JSON.parse(json);
    }
    return result;
  }

  /**
   *  Save the company scheme in local storage
   * @param {JSON} obj
   * @param {string} company_name
   */
  setCompanyScheme(obj, company_name) {
    this.resetCompanyLocals(company_name);
    let jsonstr = JSON.stringify(obj.data);
    Vue.localStorage.set(COMPANY_CONFIG_KEY + "_" + company_name, jsonstr);
    if (obj.data) {
      Vue.localStorage.set(COMPANY_LANGUAGE_KEY + "_" + company_name, obj.data.default_lang);
    }
  }

  /**
   * Save user data to localStorage.
   * @param userObj
   * @param company_name
   */
  setUser(userObj, company_name) {
    Vue.localStorage.remove(USER_CONFIG_KEY + "_" + company_name);
    let jsonstr = JSON.stringify(userObj);
    Vue.localStorage.set(USER_CONFIG_KEY + "_" + company_name, jsonstr);
  }

  /**
   * Check whether data is loaded for company (scheme in local storage)
   */
  isCompanySchemeSet() {
    let params = this.getCompanyIdfromUrl();
    if (params != "") {
      let data = Vue.localStorage.get(COMPANY_CONFIG_KEY + "_" + params);
      if (data != null) {
        return true;
      }
    }
    return false;
  }


  /**
   * Get a parameter from url
   */
  getParamFromUrl(name) {
    let params = this.getUrllets();
    let result = "";
    if (Object.keys(params).length !== 0 && params.constructor == Object && typeof params[name] !== 'undefined' && params[name] !== null) {
      result = params[name];
      result = result.replace("#", "");
    }
    return result;
  }

  /**
   * Get the company name parameter from url
   */
  getCompanyIdfromUrl() {
    return this.getParamFromUrl('company_name')
  }

  /**
   * Get the device id parameter from url
   */
  getDeviceIdfromUrl() {
    return this.getParamFromUrl('d')
  }

  getInviteCodefromUrl() {
    return this.getParamFromUrl('code')
  }

  getIsLegacyfromUrl() {
    let legacy = this.getParamFromUrl('legacy')
    return legacy === 'true' || legacy === true
  }


  /**
   * Configure the color scheme for vuetify theme based on company
   * @param {JSON} obj
   */
  configVuetify(obj) {
    let colors = this.getCompanyScheme();
    if (colors != "") {
      //accent: colors.third,
      obj.theme.themes.light = {
        primary: colors.main_color,
        secondary: colors.primary_color,
        error: colors.error_color,
        info: colors.info_color,
        success: colors.success_color,
        warning: colors.warning_color
      };

      //accent: colors.third,
      obj.theme.themes.dark = {
        primary: colors.main_color,
        secondary: colors.primary_color,
        error: colors.error_color,
        info: colors.info_color,
        success: colors.success_color,
        warning: colors.warning_color
      };
      obj.theme.isDark =
          colors.dark_scheme == null || colors.dark_scheme == false ?
              false :
              true;
    }
  }

  /**
   * Get the company name parameter
   */
  getUrllets() {
    let lets = {};
    window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (
        m,
        key,
        value
    ) {
      lets[key] = value;
    });
    return lets;
  }

  setLoginLegacyMode (company_name) {
    let jsonstr = JSON.stringify(
        {legacy: true}
    );
    Vue.localStorage.set('LEGACY_MODE' + "_" + company_name, jsonstr);
  }

  isLegacyMode () {
    // fallback if we loose because of redirect or something but should still keep in url
    let is_legacy_in_url = this.getIsLegacyfromUrl();
    let company_id_in_url = this.getCompanyIdfromUrl();
    if (is_legacy_in_url) {
      return true;
    }

    // TOOD: remove code below at XII 2021 or later
    // temporary just for backward compatibility, maybe someone got this saved
    let json = Vue.localStorage.get('LEGACY_MODE' + "_" + company_id_in_url);
    if (json) {
      let legacyObj = JSON.parse(json);
      if (legacyObj.legacy) {
        return true;
      }
    }
    return false;
  }

  setInvitationCode (company_name, code) {
    let jsonstr = JSON.stringify(
        {invitation_code: code}
    );
    Vue.localStorage.set('INVITATION_CODE' + "_" + company_name, jsonstr);
  }

  getInvitationCode (company_name) {
    let json = Vue.localStorage.get('INVITATION_CODE' + "_" + company_name);
    if (json) {
      let legacyObj = JSON.parse(json);
      if (legacyObj.invitation_code) {
        return legacyObj.invitation_code;
      }
    }
    return false;
  }

  clearInvitationCode (company_name) {
    let json = Vue.localStorage.get('INVITATION_CODE' + "_" + company_name);
    if (json) {
      Vue.localStorage.remove('INVITATION_CODE' + "_" + company_name);
    }
  }


  //BOT SECTION
  setFacebookBotHash (company_name, value) {
    let jsonstr = JSON.stringify(
        {hash: value}
    );
    Vue.localStorage.set('FACEBOOK_BOT_HASH' + "_" + company_name, jsonstr);
  }

  getFacebookBotHash (company_name) {
    let json = Vue.localStorage.get('FACEBOOK_BOT_HASH' + "_" + company_name);
    if (json) {
      let legacyObj = JSON.parse(json);
      if (legacyObj.hash) {
        return legacyObj.hash;
      }
    }
    return false;
  }

  setFacebookBotId (company_name, value) {
    let jsonstr = JSON.stringify(
        {id: value}
    );
    Vue.localStorage.set('FACEBOOK_BOT_ID' + "_" + company_name, jsonstr);
  }

  getFacebookBotId (company_name) {
    let json = Vue.localStorage.get('FACEBOOK_BOT_ID' + "_" + company_name);
    if (json) {
      let legacyObj = JSON.parse(json);
      if (legacyObj.id) {
        return legacyObj.id;
      }
    }
    return false;
  }

})();
