<template>
  <v-content>
    <div style="position: absolute; top: 20px; right: 20px; cursor: pointer;z-index: 1;" onclick="parent.postMessage('close', '*')">
      <v-icon large color="black"
              style="font-size: 25px; text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;">close
      </v-icon>
    </div>
    <v-container :style="`background-color:${config.background_color} !important; height: 100%`" v-if="state === 'initial'">
      <v-row justify="center">
        <v-col cols="12" align="center" :style="`padding-top:35px`">
          <h4 style="font-size: 22px;" :style="`color:${config.mfontcolor} !important`">{{ config.line1 }}</h4>
        </v-col>
      </v-row>
      <v-row justify="center" class="layout_login_image_container">
        <v-col cols="12" align="center">
          <v-img :src="config.layout_login_image"
                 :width="(config.layout_login_image_width == 100) ? '100%' : config.layout_login_image_width"
                 height="auto" contain></v-img>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" align="center">
          <h4 :style="`color:${config.mfontcolor} !important;font-size:24px;`">{{ config.line2 }}</h4>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" align="center" justify="center">
          <v-tabs
              :color="config.main_color"
              :background-color="config.background_color"
              height="10px"
              centered
              fixed-tabs
              hide-slider
          >
            <v-tab ripple style="pointer-events: none"></v-tab>
            <v-tab-item>
              <v-card class="main-card" :style="`background-color:${config.pcolor} !important; border-radius: 0`"
                      :border-radius="0">
                <v-row justify="center">
                  <v-col cols="12" align="center" class="mt-6">
                    <h5 class="primary--text v-tab" style="pointer-events: none" v-if="!isSocial">
                      Sprawdź bez hasła</h5>
                    <h5 class="primary--text v-tab" style="pointer-events: none" v-if="isSocial">
                      {{ $t('LoginScreen.LOGIN_USING') }}</h5>
                  </v-col>
                </v-row>
                <v-row justify="center" v-if="options.showerr && (!options.agree || login.email.length < 5 || (config.invite_code_required !== '1' || (options.invitecode == null ||options.invitecode.length < 1)) || options.facebookError)">
                  <v-col cols="10" align="center">
                    <v-alert
                        type="error"
                        transition="slide-y-reverse-transition"
                    >{{ options.errors }}
                    </v-alert>
                  </v-col>
                </v-row>
                <v-row justify="center" v-if="!isSocial">
                  <v-col cols="10">
                    <v-text-field
                        id="loginfield"
                        solo
                        v-model.trim="login.email"
                        :placeholder="$t('LoginScreen.SIMPLE_INPUT_PLACEHOLDER')"
                        :style="`color:${config.mfontcolor} !important`"
                        persistent-hint
                        autocomplete="off"
                        class="custom-label-color"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="10">
                    <v-btn
                        block
                        class="loginbtn"
                        :color="config.mcolor"
                        :style="`color:${config.mfontcolor}`"
                        @click="doLogin"
                        :loading="login.loading"
                        :disabled="login.loading"
                    >Wejdź</v-btn>
                  </v-col>
                  <v-col cols="10">
                    <v-btn
                        text
                        class="border0px pad2px"
                        small
                        :color="config.mcolor"
                        :style="`color:${config.mcolor} !important`"
                        @click="isSocial = true"
                    >
                      Inne sposoby logowania
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row v-if="isSocial">
                  <v-col cols="12" sm12 md12>
                    <div id="login-line-icons">

                      <a @click="triggerAgreementError()" style="cursor: pointer"  v-if="companyData.is_email_login > 0">
                        <div class="social-icons">
                          <a class="social-icon social-icon--email" @click="doemaillogin"
                             :class="options.agree ? '' : 'no-pointer-events'">
                            <v-icon large color="white">mdi-email</v-icon>
                            <div class="social-icon-label">Email</div>
                            <div class="tooltip">Email</div>
                          </a>
                        </div>
                      </a>

                      <a @click="triggerAgreementError()" style="cursor: pointer" v-if="companyData.is_apple_login > 0">
                        <div class="social-icons">
                          <a class="social-icon social-icon--apple" @click="doAppleLogin()"
                             :class="options.agree ? '' : 'no-pointer-events'">
                            <v-icon large color="white">mdi-apple</v-icon>
                            <div class="social-icon-label">Apple</div>
                            <div class="tooltip">Apple</div>
                          </a>
                        </div>
                      </a>

                      <a @click="showFacebookError()" style="cursor: pointer" v-if="companyData.is_facebook_login > 0">
                        <fblogin
                            :class="options.agree ? '' : 'no-pointer-events'"
                            v-if="options.showSocial"
                            v-on:showerror="showloginerror"
                            @click.native="setInviteCode"
                        ></fblogin>
                      </a>

                      <a @click="triggerAgreementError()" style="cursor: pointer" v-if="companyData.is_google_login > 0">
                        <googlelogin
                            :class="options.agree ? '' : 'no-pointer-events'"
                            v-if="options.showSocial"
                            v-on:showerror="showloginerror"
                            @click.native="setInviteCode"
                        ></googlelogin>
                      </a>

                      <a @click="triggerAgreementError()" style="cursor: pointer" v-if="companyData.is_sms_login > 0">
                        <div class="social-icons">
                          <a class="social-icon social-icon--phone" @click="dosmslogin()"
                             :class="options.agree ? '' : 'no-pointer-events'">
                            <v-icon large color="white">mdi-phone</v-icon>
                            <div class="social-icon-label">SMS</div>
                            <div class="tooltip">{{ $t('LoginScreen.LOGIN_OPTION_PHONE') }}</div>
                          </a>
                        </div>
                      </a>

                    </div>
                  </v-col>
                  <v-col cols="12" class="back-btn">
                    <v-btn
                        text
                        class="border0px pad2px"
                        small
                        :color="config.mcolor"
                        :style="`color:${config.mcolor} !important`"
                        @click="backToInitial"
                    >
                      <v-icon :style="`font-size: 16px;color: ${config.main_color}!important;`">mdi-arrow-left</v-icon>
                      {{$t('LoginScreen.BACK_BTN')}}
                    </v-btn>
                  </v-col>

                </v-row>
                <v-row v-if="isSafariBrowser && config.direct_login_link !== undefined">
                  <v-col>
                    <a @click="goToDirectLink()" :href="config.direct_login_link" target="_blank">{{ $t('LoginScreen.HAVING_TROUBLE_LOGGING') }}</a>
                  </v-col>
                </v-row>

                <v-row justify="center" v-if="config.android_app_url">
                  <v-col cols="12" align="center" class="p-0 m-0" style="padding: 0;">
                    <h6 :style="`color:${config.mfontcolor} !important; font-size: 12px;`" class="p-0 m-0">Pobierz
                      aplikację mobilną</h6>
                  </v-col>
                  <v-col cols="6" align="center" v-if="config.android_app_url" class="p-0 m-0">
                    <a :href="config.android_app_url" target="_blank" class="inline">
                      <v-img max-width="120px" src="./../assets/images/stores/google-play-badge.png"></v-img>
                    </a>
                  </v-col>
                  <v-col cols="6" align="center" v-if="config.ios_app_url" class="p-0 m-0">
                    <a :href="config.ios_app_url" target="_blank" class="inline">
                      <v-img max-width="120px" src="./../assets/images/stores/appstore-badge.png"></v-img>
                    </a>
                  </v-col>
                </v-row>

                <v-row justify="center" v-if="config.invitation_enabled">
                  <v-col cols="10">
                    <label v-if="showInvitationFieldLabel && options.invitecode" for="invitationcode"
                           :style="`color:${config.mfontcolor} !important`">{{ $t('LoginScreen.INVITECODE') }}</label>
                    <v-text-field
                        id="invitationcode"
                        solo
                        v-model="options.invitecode"
                        :placeholder="$t('LoginScreen.INVITECODE')"
                        :style="`color:${config.mfontcolor} !important`"
                        persistent-hint
                        autocomplete="off"
                        class="custom-label-color"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row justify="center">
                  <v-col cols="10" class="mb-0 pb-5">
                    <v-checkbox
                        v-if="dialog.content.length > 0"
                        v-model="options.agree"
                        :rules="[value => {return agreerule(value); }]"
                        class="checkbox-agreement"
                        :class="{'need-activation':options.showerr && !options.agree}"
                    >
                      <template v-slot:label>
                        <div class="condition">
                          <span v-text="$t('LoginScreen.IAGREE')"
                                :style="`color:${config.mfontcolor} !important`"></span>
                          <!--<v-tooltip bottom>
                            <template v-slot:activator="{ on }">-->
                          <a
                              :style="`color:${config.main_color} !important`"
                              v-text="$t('LoginScreen.TERMS')"
                              @click="showRulesDialog"
                          ></a>
                          <!--</template>
                        </v-tooltip>-->
                        </div>
                      </template>
                    </v-checkbox>

                  </v-col>
                </v-row>
              </v-card>
            </v-tab-item>
          </v-tabs>
        </v-col>
      </v-row>
      <v-dialog v-model="dialog.show" fullscreen>
        <v-card>
          <v-toolbar dark color="primary">
            <v-btn icon dark @click="dialog.show = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title v-text="$t('LoginScreen.RULES_TITLE')"></v-toolbar-title>
          </v-toolbar>
          <v-card-text v-html="dialog.content"></v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                :color="config.pcolor"
                :style="`color:${config.pfontcolor} !important`"
                text
                @click="agreeRules"
                v-text="$t('LoginScreen.ACCEPT_BTN')"
            ></v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-footer
          absolute
          :style="`position:fixed;`"
      >
        <div style="float:left;font-size: 13px;width: 50%" id="footer-left">
          Powered by
          <a :href="poweredUrl" class="extarnal animated-hover"
             style="border-bottom: 2px dotted #323d48;text-decoration: none;" target="_blank"
             v-text="poweredCompany"></a>

        </div>
        <div class="clr"></div>
        <div style="float:right;width: 50%;font-size: 13px;text-align: right;" id="footer-right"
             v-text="companyData.name"></div>
        <div class="clr"></div>
      </v-footer>
    </v-container>
    <v-container :style="`background-color:${config.background_color} !important; height: 100%`" v-if="state === 'awaiting'">
      <v-row justify="center">
        <v-col cols="12" align="center" :style="`padding-top:35px`">
          <h4 style="font-size: 22px;" :style="`color:${config.mfontcolor} !important`">{{ config.line1 }}</h4>
        </v-col>
      </v-row>
      <v-row justify="center" class="layout_login_image_container">
        <v-col cols="12" align="center">
          <v-img :src="config.layout_login_image" :width="(config.layout_login_image_width == 100) ? '100%' : config.layout_login_image_width" height="auto" contain></v-img>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" align="center">
          <h4 :style="`color:${config.mfontcolor} !important`">{{ awaiting_message }}</h4>
        </v-col>
      </v-row>

      <v-row justify="center" class="back-btn">
        <v-btn
            text
            class="border0px pad2px"
            small
            :color="config.mcolor"
            :style="`color:${config.mcolor} !important`"
            @click="backToInitial"
        >

          <v-icon :style="`font-size: 16px;color: ${config.main_color}!important;`">mdi-arrow-left</v-icon>
          {{$t('LoginScreen.BACK_BTN')}}
        </v-btn>

        <v-col cols="12" align="center" justify="center">
          <v-card>
            <v-row justify="center">
              <v-col cols="12" align="center" class="mt-6 pt-6 pb-12">
                <v-img src="../assets/icon-envelope.png" width="55" height="auto" contain></v-img>
                <h4 class="primary--text v-tab" style="pointer-events: none">
                  {{ getLinkSentText()}}</h4>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <v-footer
          absolute
          :style="`position:fixed;`"
      >
        <div style="float:left;font-size: 13px;width: 50%" id="footer-left">
          Powered by
          <a :href="poweredUrl" class="extarnal animated-hover" style="border-bottom: 2px dotted #323d48;text-decoration: none;" target="_blank" v-text="poweredCompany"></a>

        </div>
        <div class="clr"></div>
        <div style="float:right;width: 50%;font-size: 13px;text-align: right;" id="footer-right" v-text="companyData.name"></div>
        <div class="clr"></div>
      </v-footer>
    </v-container>
  </v-content>
</template>
<script>
import Vue from "vue";
import token from "@/core/auth";
import companyconfig from "@/core/companyconfig";
import loginapi from "@/services/loginapi";
import Fblogin from "@/components/auth/fblogin.vue";
import googlelogin from "@/components/auth/googlelogin.vue";

export default {
  data: () => ({
    config: {},
    login: {
      valid: true,
      email: "",
      password: "",
      invitecode: "",
      errors: "",
      showerror: false,
      show1: false,
      loading: false
    },
    options: {
      showerr: false,
      errors: "",
      agree: false,
      invitecode: Vue.localStorage.get('ref' + "_" + companyconfig.getCompanyIdfromUrl()),
      showSocial: false,
      facebookError: false
    },
    dialog: {
      show: false,
      content: ""
    },
    user_id: false,
    isSafariBrowser: false,
    showInvitationFieldLabel: false,
    companyData: companyconfig.getCompanyScheme(),
    poweredCompany: companyconfig.getCompanyScheme().partner_name, //=== false ? '2take.it' : 'Blovly',
    poweredUrl: companyconfig.getCompanyScheme().pbl,
    state: 'initial',
    awaiting_message: '',
    ajaxInterval: false,
    isSocial: false
  }),
  created() {
    this.setupLayout();
    let passedCompanyId = companyconfig.getCompanyIdfromUrl();
    if (companyconfig.allowEmailLoginOnly()) {
      this.options.showSocial = !parseInt(companyconfig.allowEmailLoginOnly());
    } else if (token.isLoggedIn()) {
      this.$router.push({
        name: "rules"
      });
    }

    //Setup invitation code passed from URL
    if (companyconfig.getInvitationCode(passedCompanyId)) {
      this.showInvitationFieldLabel = true;
      this.options.invitecode = companyconfig.getInvitationCode(passedCompanyId);
    }
    this.checkSafariBrowser();
  },
  mounted() {
    console.log('version 111');
  },
  methods: {
    /** Invite code which will be set when navigate from google/fb page */
    setInviteCode() {
      let passedCompanyId = companyconfig.getCompanyIdfromUrl();
      this.$localStorage.remove("invitecode_" + passedCompanyId);
      if (this.options.invitecode != "") {
        this.$localStorage.set(
            "invitecode_" + passedCompanyId,
            this.options.invitecode
        );
      }
    },
    showFacebookError() {
      // if(this.companyData.partner_name === 'Blovly') {
      //   if(this.options.agree) {
      //     this.options.facebookError = true;
      //     this.showloginerror('Informujemy, że logowanie przez Facebook chwilowo nie działa. Obecnie pracujemy nad rozwiązaniem tego problemu.\nW tym czasie, jeśli chcesz się zalogować, napisz do nas przez nasz chat. Przyznamy  dostęp do konta za pomocą adresu email.\nPrzepraszamy za wszelkie niedogodności i dziękujemy za wyrozumiałość');
      //     this.setInviteCode();
      //     this.options.showerr = true;
      //   } else {
      //     this.triggerAgreementError();
      //   }
      // } else {
        this.triggerAgreementError();
      // }
    },
    /** Show the rules dialog */
    showRulesDialog() {
      this.dialog.show = true;
    },
    /** Hide the rules dialog */
    agreeRules() {
      this.dialog.show = false;
      this.options.agree = true;
    },
    /** Redirect to email login screen */
    doemaillogin() {
      this.$router.push({
        name: "signup"
      });
    },
    doAppleLogin() {
      const passedCompanyId = companyconfig.getCompanyIdfromUrl();
      const clientId = 'it.2take.login';
      const redirectUrl = 'https://login.2take.it/api/web/user/apple-login?cn=' + passedCompanyId; //INFO: Redirect url should be whitelisted there: https://developer.apple.com/account/resources/identifiers/serviceId/edit/C9T39C2UB5
      const responseType = 'code%20id_token';
      const state = '';
      const scope = 'name%20email';
      const responseMode = 'form_post';

      const link = 'https://appleid.apple.com/auth/authorize?client_id=' + clientId
          + '&redirect_uri=' + redirectUrl
          + '&response_type=' + responseType
          + '&state=' + state
          + '&scope=' + scope
          + '&cookie_policy=none'
          + '&response_mode=' + responseMode;

      //Open Apple site in new window. Apple not allows to show in iframe
      window.open(link, 'Loyalty', 'width=350,height=' + screen.height);
      //Close widget
      parent.postMessage("close", '*');
    },
    dosmslogin() {
      this.$router.push({
        name: "signupsms"
      });
    },
    /**
     * Set the Intial color configuration for page
     */
    setupLayout() {
      if (this.companyData != "") {
        Vue.set(this.config, "logo", this.companyData.logo_image);
        Vue.set(this.config, "layout_login_image", this.companyData.layout_login_image);
        Vue.set(this.config, "layout_login_image_width", this.companyData.layout_login_image_width);
        Vue.set(this.config, "loginbg", this.companyData.Login_btn_bg);
        Vue.set(this.config, "logincolor", this.companyData.Login_btn_color);
        Vue.set(this.config, "logintabbg", this.companyData.Login_Tab_bg);
        Vue.set(this.config, "logintabcolor", this.companyData.Login_Tab_color);
        Vue.set(this.config, "line1", this.companyData.login_text_1_line);
        Vue.set(this.config, "line2", this.companyData.login_text_2_line);
        Vue.set(this.dialog, "content", this.companyData.company_rules);
        Vue.set(this.config, "mcolor", this.companyData.main_color);
        Vue.set(this.config, "mfontcolor", this.companyData.main_font_color);
        Vue.set(this.config, "pcolor", this.companyData.primary_color);
        Vue.set(this.config, "pfontcolor", this.companyData.primary_font_color);
        Vue.set(this.config, "background_color", this.companyData.background_color);
        Vue.set(this.config, "android_app_url", this.companyData.android_app_url);
        Vue.set(this.config, "ios_app_url", this.companyData.ios_app_url);
        Vue.set(this.config, "direct_login_link", this.companyData.direct_login_link);
        Vue.set(this.config, "invitation_enabled", this.companyData.invitation_enabled);
        Vue.set(this.config, "partner_name", this.companyData.partner_name);
        Vue.set(this.config, "invite_code_required", this.companyData.invite_code_required);
      }
    },
    /** Terms agree checkbox mandatory */
    agreerule(value) {
      if (!value) {
        return "";
      }
      return true;
    },
    /** Show login errors */
    showloginerror(error) {
      this.options.showerr = false;
      this.options.errors = error;
      this.options.showerr = true;
    },
    triggerAgreementError() {
      if (this.options.agree) {
        this.options.showerr = false;
        return true;
      } else {
        this.options.showerr = false;
        this.options.errors = "";
        this.options.errors = this.$i18n.t('LoginScreen.NEED_RULES_ACCEPTANCE');
        this.options.showerr = true;
        this.scrollToBottom();
      }
    },
    checkSafariBrowser() {
      var ua = navigator.userAgent.toLowerCase();
      if (ua.indexOf('safari') != -1) {
        if (ua.indexOf('chrome') > -1) {
          return;
        } else {
          this.isSafariBrowser = true;
        }
      }
    },
    goToDirectLink() {
      parent.postMessage('close', '*')
      console.log("Navigationg");
    },
    scrollToBottom() {
      setTimeout(function () {
        window.scrollTo(0, document.body.scrollHeight);
      }, 70)
    },
    doLogin() {
      this.login.valid = this.login.email.length > 5
          && (this.config.invite_code_required !== '1' || (this.options.invitecode != null && this.options.invitecode.length > 0));

      if(!this.options.agree) {
        this.options.showerr = false;
        this.options.errors = "";
        this.options.errors = this.$i18n.t('LoginScreen.NEED_RULES_ACCEPTANCE');
        this.options.showerr = true;
        this.scrollToBottom();
        console.log('need rules acceptance');
        return;
      }

      if (this.login.email.length < 5) {
        this.options.showerr = false;
        this.options.errors = "";
        this.options.errors = this.$i18n.t('LoginScreen.REQUIRED');
        this.options.showerr = true;
        console.log('email required');
        this.scrollToBottom();
        return;
      }

      if (this.config.invite_code_required === '1' && (this.options.invitecode == null || this.options.invitecode.length < 1)) {
        this.options.showerr = false;
        this.options.errors = "";
        this.options.errors = this.$i18n.t('LoginScreen.INVITECODE_REQUIRED');
        this.options.showerr = true;
        console.log('invite code required');
        this.scrollToBottom();
        return;
      }

      console.log('do login');
      this.doSimpleLoginRequest();
    },
    doSimpleLoginRequest() {
      let passedCompanyId = companyconfig.getCompanyIdfromUrl();

      this.login.showerror = false;
      this.login.loading = true;

      let guest_hash = localStorage.getItem('guest_hash');
      if(guest_hash) {
        let googletag = typeof window.gtag !== 'undefined' ? window.gtag : undefined;
        loginapi.setGuestHash(guest_hash, passedCompanyId, googletag);
      }

      var login_value = this.login.email;
      var instance = this;
      loginapi
          .doSimpleLogin(
              Vue.prototype.$d,
              passedCompanyId,
              this.options.invitecode,
              companyconfig.isLegacyMode(passedCompanyId),
              login_value
          )
          .then(response => {
            console.log(response.data);

            if(response.data.user) {
              this.user_id = response.data.user.id;
              console.log('user_id: ' + this.user_id)
            }

            if (response.data.status === 'registered' || response.data.status === 'logged_in') {
              companyconfig.clearInvitationCode(passedCompanyId);
              this.goLegacyUrl(response.data.redirectUrl);
              return;
            } else if(response.data.status ===  'device_changed') {
              instance.showAwaitingState(this.$i18n.t('LoginScreen.DEVICE_CHANGED'), response.data.user.id,true, 'deviceconfirmcheck')
            } else if(response.data.status ===  'verification_expired') {
              instance.showAwaitingState(this.$i18n.t('LoginScreen.NOT_VERIFIED'), response.data.user.id, true, 'userconfirmcheck')
            } else if(response.data.status ===  'invite_code_required') {
              instance.login.errors = this.$i18n.t('LoginScreen.INVITECODE_REQUIRED');
              instance.options.invitecode = '';
              instance.login.showerror = true;
            } else {
              instance.login.errors = this.$t("Errors.INCORRECT_EMAIL_OR_PASSWORD");
              instance.login.showerror = true;
            }
            instance.login.loading = false;
          })
          .catch(response => {
            console.log('error 548 :')
            console.log(response)

          });
    },
    usernamerule(value) {
      if (!value) {
        this.options.showerr = false;
        this.options.errors = "";
        this.options.errors = 'Telefon lub e-mail jest wymagany';
        this.options.showerr = true;
        this.scrollToBottom();
        return 'Telefon lub e-mail jest wymagany';
      }
      return true;
    },
    goLegacyUrl (url) {
      let passedDeviceId = companyconfig.getDeviceIdfromUrl();
      url += (url.indexOf('?') < 0 ? '?' : '&') + 'd='+passedDeviceId;
      location.href = url;
    },
    showAwaitingState(message, user_id = 0 ,start_ajax_requests = false, method = 'deviceconfirmcheck') {
      this.awaiting_message = message;
      this.state = 'awaiting';

      if(start_ajax_requests && !this.ajaxInterval) {
        let instance = this;
        this.ajaxInterval = setInterval(function () {
          loginapi
              .doStatusCheck(
                  Vue.prototype.$d,
                  companyconfig.getCompanyIdfromUrl(),
                  user_id,
                  method
              )
              .then(response => {
                console.log(response.data);
                if (response.data.status === "VERIFIED" || response.data.status === 'confirmed') {
                  instance.doSimpleLoginRequest();
                  clearInterval(instance.ajaxInterval);
                  instance.ajaxInterval = false;
                }
              })
              .catch(response => {
                console.log(response)
              });
        }, 2000)


      }
    },
    backToInitial() {
      if(this.ajaxInterval) {
        clearInterval(this.ajaxInterval);
      }
      this.state = 'initial';
    },
    getLinkSentText() {
      var message = this.login.email.includes('@') ? 'Użyj linku w wiadomości e-mail, aby potwierdzić zmianę urządzenia.' : 'Skorzystaj z linku w wiadomości SMS aby potwierdzić zmianę urządzenia.';
      return message;
    }
  },
  components: {
    Fblogin,
    googlelogin,
  }
};
</script>

<style>

#login-line-icons {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  min-height: 50px;
}

/* Color Variables */
/* Social Icon Mixin */
/* Social Icons */
.social-icons {
  display: -webkit-box;
  display: flex;
}

.social-icon {
  display: -webkit-box;
  display: block;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  width: 50px;
  height: 50px;
  margin: 0 5px;
  /*border-radius: 50%;*/
  cursor: pointer;
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-size: 2.5rem;
  text-decoration: none;
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.social-icon:hover {
  color: #fff;
}

.social-icon:hover .tooltip {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translate(-50%, -150%);
  transform: translate(-50%, -150%);
}

.social-icon:active {
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5) inset;
}

.social-icon--facebook {
  background: #3b5a9b;
  color: #fff;
}

.social-icon--facebook .tooltip {
  background: #3b5a9b;
  color: currentColor;
}

.social-icon--facebook .tooltip:after {
  border-top-color: #3b5a9b;
}

.social-icon--google {
  background: #4385f4;
  color: #fff;
}

.social-icon--google .tooltip {
  background: #4385f4;
  color: currentColor;
}

.social-icon--google .tooltip:after {
  border-top-color: #4385f4
}

.social-icon--phone {
  background: #f4b753;
  color: #fff;
}

.social-icon--phone .tooltip {
  background: #f4b753;
  color: currentColor;
}

.social-icon--phone .tooltip:after {
  border-top-color: #f4b753
}

.social-icon--email {
  background: #f46178;
  color: #fff;
}

.social-icon--email .tooltip {
  background: #f46178;
  color: currentColor;
}

.social-icon--email .tooltip:after {
  border-top-color: #f46178
}

.social-icon--apple {
  background: #000000;
  color: #fff;
}

.social-icon--apple .tooltip {
  background: #000000;
  color: currentColor;
}

.social-icon--apple .tooltip:after {
  border-top-color: #000000
}

.social-icon i {
  position: relative;
  top: -12px;
  margin: 0 !important;
}

.social-icon-label {
  font-size: 7.5pt;
  margin-top: -25px;
  color: #fff;
  white-space: nowrap;
}

/* Tooltips */
.tooltip {
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  padding: 0.8rem 1rem;
  border-radius: 40px;
  font-size: 0.8rem;
  font-weight: bold;
  opacity: 0;
  pointer-events: none;
  text-transform: uppercase;
  -webkit-transform: translate(-50%, -100%);
  transform: translate(-50%, -100%);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  z-index: 1;
}

.tooltip:after {
  display: block;
  position: absolute;
  bottom: 1px;
  left: 50%;
  width: 0;
  height: 0;
  content: "";
  border: solid;
  border-width: 10px 10px 0 10px;
  border-color: transparent;
  -webkit-transform: translate(-50%, 100%);
  transform: translate(-50%, 100%);
}

.no-pointer-events {
  pointer-events: none
}

.v-input--selection-controls {
  padding-top: 0;
  margin-top: 0;
}

.v-text-field__details {
  display: none;
}

.checkbox-agreement .v-input__slot {
  padding: 5px;
}

.checkbox-agreement.need-activation .v-input__slot {
  border-color: #d64343 !important;
  border: 1px solid;
  border-radius: 5px;
}

.checkbox-agreement.need-activation i.v-icon {
  color: #d64343;
}

</style>
