<template>
    <v-dialog
            v-model="childVisible"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition">
        <v-card class="modelFullscreen" style="text-align: center" align="center">
            <div class="pt-12">
                <v-img
                        :src="config.shortlogo"
                        max-height="80px"
                        contain
                        v-if="config.showLogo"
                ></v-img>
            </div>
            <div class="pt-12">
                <h5 class="modalHeader">{{$t('TaskScreen.MODAL_SUCCESS_TITLE')}}</h5>
            </div>
            <div class="pt-8 pl-5 pr-5">
                <p v-if="is_matched" class="modalInfoText">{{$t('TaskScreen.MODAL_SUCCESS_MATCHED')}}</p>
                <p v-if="!is_matched" class="modalInfoText">{{$t('TaskScreen.MODAL_SUCCESS_UNMATCHED')}}</p>
            </div>

            <div class="pt-8 pl-5 pr-5">
                <select class="fleft" @change="onProductChange($event.target.value)">
                    <option v-for="(product, index) in products" :value="index+1" v-bind:key="index" >{{ product.name }}</option>
                </select>
                <select class="fleft" @change="onIncentiveChange($event.target.value)">
                    <option v-for="(incentive, id) in incentives" :value="id" v-bind:key="id" >{{ incentive.name }}</option>
                </select>
            </div>

            <div class="pt-2">
                <div>
                    <img src="../../../assets/images/receipt/check.png" class="progressModalImage"/>
                </div>
            </div>

            <v-btn @click="hide" class="mt-12">
                {{$t('TaskScreen.SCAN_CLOSE')}}
            </v-btn>
        </v-card>
    </v-dialog>
</template>
<script>
    import companyconfig from "@/core/companyconfig";
    export default {
        data() {
            return {
                childVisible: true,
                config: {},
                product: 1,
                incentive: 0
            }
        },

        props: {
            is_matched: {
                type: Boolean,
                default: true
            },
            products: {
                type: Array,
                default: function () { return []; }
            },
            incentives: {
                type: Array,
                default: function () { return []; }
            }
        },
        created() {
            this.setupLayout();
        },
        methods: {
            hide () {
                if (this.is_matched) this.$emit('success-modal-hide', false, false)
                else {
                    if (this.product < 1 || this.incentive < 1) alert ('Please select.');
                    else this.$emit('success-modal-hide', this.product, this.incentive)
                }
            },
            onProductChange (value) {
                this.product = value
            },
            onIncentiveChange (value) {
                this.incentive = value
            },
            setupLayout() {
                let data = companyconfig.getCompanyScheme();
                if (data != "") {
                    this.$set(this.config, "mcolor", data.main_color);
                    this.$set(this.config, "mfontcolor", data.main_font_color);
                    this.$set(this.config, "shortlogo", data.shortLogo);
                    this.$set(this.config, "showLogo", data.showLogo);
                    this.$set(this.config, "showMenu", data.showMenu);
                    this.$set(this.config, "logoPosition", data.logoPosition);
                }
            },
        }
    }
</script>
