import axiosinstance from "@/core/axios";
import GlobalConfig from "@/variable";

export default {

    storage(token, companyname, receipt_id) {
        var url;
        if (typeof(receipt_id) === 'undefined' || receipt_id === null || receipt_id == 0) {
            url = GlobalConfig.baseUrl + "receipt/storage";
        } else {
            url = GlobalConfig.baseUrl + "receipt/storage?rid="+receipt_id;
        }
        return axiosinstance.post(url, {
          access_token: token,
          url_name: companyname
        });
    },
    process(token, companyname, receipt_id) {
        let url = GlobalConfig.baseUrl + "receipt/process?rid="+receipt_id;
        return axiosinstance.post(url, {
          access_token: token,
          url_name: companyname
        });
    },
    poll(token, companyname, receipt_id) {
        let url = GlobalConfig.baseUrl + "receipt/poll?rid="+receipt_id;
        return axiosinstance.post(url, {
          access_token: token,
          url_name: companyname
        });
    },
    mark(token, companyname, receipt_id) {
        let url = GlobalConfig.baseUrl + "receipt/mark?rid="+receipt_id;
        return axiosinstance.post(url, {
          access_token: token,
          url_name: companyname
        });
    },

}