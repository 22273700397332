<template>
  <v-card flat tile>
    <v-app-bar
      app
      fixed
      dense
      flat
      elevate-on-scroll
      :color="config.mcolor"
      :style="`color:${config.mfontcolor} !important`"
    >
      <v-app-bar-nav-icon
        class="border0px"
        :style="`color:${config.mfontcolor} !important`"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <pageheader></pageheader>
      <template v-slot:extension v-if="config.showMenu">
        <v-tabs
          align-with-title
          v-model="tab"
          :background-color="config.mcolor"
          :color="config.mfontcolor"
        >
          <v-tabs-slider></v-tabs-slider>
          <v-tab @click="navigatehome">Home</v-tab>
        </v-tabs>
      </template>
    </v-app-bar>
    <span id="close-widget-btn" @click="closeWidget()">
      <v-icon>mdi-close</v-icon>
    </span>
    <v-navigation-drawer v-model="drawer" absolute temporary style="top:0px">
      <v-list-item class="px-2">
        <v-list-item-avatar>
          <v-icon>mdi-account-circle</v-icon>
        </v-list-item-avatar>

        <v-list-item-title id="layoutUserID">{{$t("AppScreen.USER_ID") + ": " + loggedUser.user_id}}</v-list-item-title>

        <v-btn icon @click.stop="drawer = !drawer">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </v-list-item>

      <v-divider></v-divider>
      <v-list nav dense>
        <v-list-item-group>
          <v-list-item>
            <v-list-item-title @click="navigatehome">{{$t("AppScreen.GO_HOME")}}</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title @click="logout">{{$t("AppScreen.LOGOUT")}}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-content class="min-height mb-6">
      <div class="pa-0 mb-6">
        <router-view></router-view>
      </div>
    </v-content>
    <v-footer
      absolute
      :color="config.mcolor"
      :style="`color:${config.mfontcolor} !important`"
    >
      <div class="d-flex justify-space-between" style="width:100%">
        <div v-text="companyname"></div>
        <div v-text="year"></div>
      </div>
    </v-footer>
  </v-card>
</template>

<script>
import companyconfig from "@/core/companyconfig";
import pageheader from "@/components/home/header";
import auth from "@/core/auth";
import Vue from "vue";
export default {
  data() {
    return {
      config: {},
      companyname: "",
      year: 0,
      tab: "tab1",
      drawer: false,
      loggedUser: false
    };
  },
  created() {
    this.setupLayout();
    this.getUser();
  },
  computed: {
    menuItems() {
      let menuItems = [{ icon: "lock_open", title: "Home", link: "/Logout" }];
      return menuItems;
    }
  },
  components: {
    pageheader
  },
  methods: {
    /** Navigate to home page */
    navigatehome() {
      this.$router.push({
        name: "home"
      });
      this.drawer = false;
    },
    /**
     * Set the Intial color configuration for page
     */
    setupLayout() {
      let data = companyconfig.getCompanyScheme();
      let passedCompanyId = companyconfig.getCompanyIdfromUrl();
      if (data != "") {
        this.$set(this.config, "mcolor", data.main_color);
        this.$set(this.config, "mfontcolor", data.main_font_color);
        this.$set(this.config, "showMenu", data.showMenu);
      }
      this.companyname = passedCompanyId;
      this.year = new Date().getFullYear();
    },
    getUser() {
      let passedCompanyId = companyconfig.getCompanyIdfromUrl();
      let data = companyconfig.getUserScheme(passedCompanyId);
      if (data != "" && data.user_id) {
        this.loggedUser = data;
        window.localStorage.setItem('system_user_id', data.user_id); //NEEDED FOR "SUPPORTBOARD" CHAT SYNCHRONIZATION
      }
    },
    /** Logout the user. */
    logout() {
      let passedCompanyId = companyconfig.getCompanyIdfromUrl();
      Vue.prototype.$token = null;
      companyconfig.resetCompanyLocals(passedCompanyId);
      this.$router.push({
        name: "welcome"
      });
      auth.clearTokens();
    },
    closeWidget() {
      parent.postMessage("close", '*');
    }
  }
};
</script>
<style>
.min-height {
  min-height: 100vh;
  min-height: calc(let(--vh, 1vh) * 100);
}
</style>
