import Vue from "vue";
import vuetify from "./plugins/vuetify";
import "./plugins/vuestorage";
import "./plugins/socialplugins";
import "./plugins/clipboard";
import App from "./App.vue";
import router from "./router";
import { i18n } from "./plugins/i18n";

import * as Sentry from "@sentry/vue";
import * as Integrations from '@sentry/integrations';
import { BrowserTracing } from "@sentry/tracing";

import $2TI from "./plugins/common2ti"

require('./assets/css/styles.css');

Vue.config.productionTip = false;

Sentry.init({
  Vue,
  dsn: "https://a839fab77e4b4650a83fee23b73aee2f@o1232060.ingest.sentry.io/6462507",
  integrations: [
    new Integrations.Vue({
      Vue,
      attachProps: true,
      logErrors: true
    }),
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
  ],
  tracesSampleRate: 0.01, // 1%
  blacklistUrls: ['pagead'],
  ignoreErrors: [
    // Random plugins/extensions
    "top.GLOBALS",
    "viewthroughconversion",
    "pagead",
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    "originalCreateNotification",
    "canvas.contentDocument",
    "MyApp_RemoveAllHighlights",
    "http://tt.epicplay.com",
    "Can't find variable: ZiteReader",
    "jigsaw is not defined",
    "ComboSearch is not defined",
    "http://loading.retry.widdit.com/",
    "atomicFindClose",
    // Facebook borked
    "fb_xd_fragment",
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
    // reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268
    "bmi_SafeAddOnload",
    "EBCallBackMessageReceived",
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    "conduitPage",
    // VUE unfindable error
    "Non-Error promise rejection captured with value: undefined",
    // Failed network fetches when they don't cause other errors
    'TypeError: Failed to fetch',
    'TypeError: NetworkError when attempting to fetch resource.'
  ],
  denyUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    // chat
    /chat\.system\./i,
    /chat\.dev\./i
  ]
});

$2TI.setupUTMHandler();
$2TI.attachMessageDispatcher();
setTimeout(()=>{$2TI.triggerWindow(false, 'loaded2ti')},1000);

const originalPush = router.push
router.push = (location)=>{
  return originalPush.call(router,location).catch(e=>{
    console.log(`error in router ${e}`);
  })
}

new Vue({
  router,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount("#app");


